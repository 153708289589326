import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com'

const initialState = {
  name: '',
  branch: '',
  mobile: '',
  email: '',
  message: '',
}

var d = new Date();
var currYear = d.getFullYear();

export const Contact = (props) => {
  const [{ name, branch, mobile, email, message }, setState] = useState(initialState)

  const MapContainer = () => {
    useEffect(() => {
      // Force reload the iframe when the component mounts
      const iframe = document.getElementById('map-iframe');
      iframe.src = iframe.src;
    }, []);
    return (
      <div>
        <iframe id="map-iframe" src={props.data ? props.data.gmap : 'loading'} width="350" height="350" style={{ border: 0, borderRadius: "25px" }} loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, branch, mobile, email, message)
    emailjs
      .sendForm(
        'service_c5muvbg', 'template_c78pk2t', e.target, 'fNiiZF9g4hO9pHUNd'
      )
      .then(
        (result) => {
          alert('Thanks for showing interest in readers rejoice library.... An executive will call you soon.')
          clearState()
        },
        (error) => {
          console.log(error.text)
        }
      )
  }
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-4'>
            <div className='row'>
              <div className='section-title'>
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Name*'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>


                      <select className="form-select form-control" id='branch' name='branch' aria-label="Branch select" onChange={handleChange}>
                        <option selected>Select Branch</option>
                        <option value="dcg">Doctor's Colony, Kankarbagh</option>
                        <option value="ebcr">East Boring Canal Road</option>
                        <option value="wbcr">West Boring Canal Road</option>
                        <option value="kfr">Kanti Factory Road</option>

                      </select>




                      <p className='help-block text-danger'></p>

                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='number'
                        id='mobile'
                        name='mobile'
                        className='form-control'
                        placeholder='Mobile Number*'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email*'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>

                    </div>
                  </div>
                </div>


                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Message'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg'>
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Address
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Phone
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='row'>
              <div className='section-title center'>
                <h2>Reach US</h2>{''}
                <MapContainer />
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  )
}
