import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Ecbr from '../Home/ebcr'; 


const Card = (props) => {
    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    return (
        <div className='card-block'>
            <div className="card col-md-3 col-8 mx-auto">
                <div>
                    <img className="card-img-top img-responsive col-md-12 img-fluid" src={props.imgsrc} alt={props.imgsrc} />
                    <div className="card-body">
                        <h5 className="card-title font-weight-bold">{props.title}</h5>
                        <div className='iconamity '>
                            <img src={props.icon1}></img>
                            <img src={props.icon2}></img>
                            <img src={props.icon3}></img>
                            <img src={props.icon4}></img>
                            
                           

                            <div className="popup-container iconamityicon" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                {/* 
                                    <div className={`popup ${isHovered ? 'show' : ''}`}>
                                    <i className="fa fa-hourglass-half"> 24 hr Open</i><br/>
                                    <i className="fa fa-codepen"> Cubical with chair</i><br/>
                                    <i className="fa fa-users">Discussion Room</i><br/>
                                    <i className="fa fa-lightbulb-o"> Separate light</i><br/>
                                    <i className="fa fa-bolt"> Charging point</i><br/>
                                    <i className="fa fa-wifi"> Wifi</i><br/>
                                    <i className="fa fa-snowflake-o"> Ac Cabins</i><br/>
                                    <i className="fa fa-tint"> RO Water</i><br/>
                                    <i className="fa fa-newspaper-o">Newspaper</i><br/>
                                    <i className="fa fa-book"> Books</i><br/>
                                    <i className="fa fa-archive"> Locker</i><br/>
                                </div>
                                */}
                                <a href={props.url} className="open-button">More</a>
                            </div>
                            <div className="branch_button">
                            <a href={props.url} className="btn-left">View Details</a>
                    
                        
                            <a href="/register" className="btn-right">Book Now</a>
                            </div>

                        </div>
                        </div>
                        
                </div>
            </div>
        </div>
    );
}

export default Card;

// <p className="card-text">{props.detail}</p> <a href={props.url} className="open-button">More</a>