import React, { useState, useEffect } from 'react';
import JsonData from "../../data/data.json";
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import emailjs from 'emailjs-com'

const initialState = {
    fname: '',
    mname: '',
    lname: '',
    mobile: '',
    email: '',
    branch: '',
    shift: '',
    period: '',
}
export const CheckIn = (props) => {
    const articleSchema = {
        "@context": "https://schema.org",
        "@type": "Article",
        "headline": "Readers Rejoice Library",
        "author": {
          "@type": "Person",
          "name": "Wappotix"
        },
        "datePublished": "2024-09-01",
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "http://readersrejoice.in/checkin"
        },
        "articleBody": "Best Library in Doctors Coloney"
      };
    useEffect(() => {
        window.scrollTo(0, 0)

    }, []);



    const [{ fname, mname, lname, mobile, email, branch, shift, period }, setState] = useState(initialState)

    const handleChange = (e) => {
        const { name, value } = e.target
        setState((prevState) => ({ ...prevState, [name]: value }))
    }

    const clearState = () => setState({ ...initialState })

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(fname, mname, lname, shift, period, branch, mobile, email)
        emailjs
            .sendForm(
                'service_q3em88d', 'template_2rw5xxs', e.target, 'O9lL0Ye_2ohp65SJh'
            )
            .then(
                (result) => {
                    alert('Thanks for showing interest in readers rejoice library.... An executive will call you soon.')
                    clearState()
                },
                (error) => {
                    console.log(error.text)
                }
            )
    }


    const [value, setValue] = useState();
    const navigate = useNavigate();

    return (
        <div id="checkin">
            <div className="c-10izwvm">
                <div className="c-1vrj4pl">
                    <NavLink to="/" >{/*navigate(-1)*/}
                        <span className="c-1aivkbe">
                            <svg xmlns="http://www.w3.org/2000/svg" id="chevron" viewBox="0 0 8 14">
                                <path d="M.68-.04c.173 0 .346.066.48.2L8 7l-6.84 6.84a.677.677 0 01-.96 0 .677.677 0 010-.96L6.08 7 .2 1.12a.675.675 0 010-.96c.13-.134.305-.2.48-.2z">
                                </path>
                            </svg>
                        </span>&nbsp;<span className="c-isphd1">
                            <span>Modify your booking</span>
                        </span>
                    </NavLink>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-md-8">
                        <div className="c-172kp3s">
                            {/* 
              <div className="c-12qt2ez">
                  <span>
                      <span role="img" aria-label="discount">🎉</span>
                      <span>Yay! you just saved ₹4279 on this booking!</span>
                  </span>
              </div>
          */}
                            <div className="c-8kaq9v">
                                <div className="c-0">
                                    <div>
                                        <div className="c-i9gwvb">
                                            <div className="c-1kz1i96">1</div>
                                            <div className="c-i9gxme">
                                                <span>Enter your details</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="c-1rhsl0p">
                                        <form name='sentMessage' validate onSubmit={handleSubmit}>
                                            <div className="c-mjbegz">
                                                <div className="c-1s0ylt3">
                                                    We will use these details to share your booking information, Thanks for joining Us!
                                                </div>
                                                <div id="fullname">

                                                </div>
                                                <div className="c-qln3k62">
                                                    <div className="textInput__container">
                                                        <div className="textInput__inputLabel" htmlfor="name">
                                                            <span>First Name*</span>
                                                        </div>
                                                        <input className="textInput__input" id="fname" name='fname' required type="text" placeholder="First name" label="[object Object]" maxlength="50" onChange={handleChange} />
                                                        <div className="textInput__inputLabelHelper">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="c-qln3k62">
                                                    <div className="textInput__container">
                                                        <div className="textInput__inputLabel" htmlfor="name">
                                                            <span>Middle Name</span>
                                                        </div>
                                                        <input className="textInput__input" id="mname" name='mname' type="text" placeholder="Middle name" label="[object Object]" maxlength="50" onChange={handleChange} />
                                                        <div className="textInput__inputLabelHelper">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="c-qln3k62">
                                                    <div className="textInput__container">
                                                        <div className="textInput__inputLabel" htmlfor="name">
                                                            <span>Last Name*</span>
                                                        </div>
                                                        <input className="textInput__input" id="lname" name='lname' required type="text" placeholder="Enter last name" label="[object Object]" maxlength="50" onChange={handleChange} />
                                                        <div className="textInput__inputLabelHelper">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="c-qln3k6">
                                                    <div className="textInput__container">
                                                        <div className="textInput__inputLabel" htmlfor="email">
                                                            <span>Email Address*</span>
                                                        </div>
                                                        <input className="textInput__input" id="email" name='email' required type="email" label="[object Object]" placeholder="name@abc.com" maxlength="75" notshowflag="true" onChange={handleChange} />
                                                        <div className="textInput__inputLabelHelper">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="c-qln3k6">
                                                    <div className="textInput__inputLabel">
                                                        <span>Mobile Number</span>
                                                    </div>
                                                    <div className="textTelInput__container">


                                                        <span className="c-1wjqvmt textTelInput__inputCountryCode textTelInput__inputCountryCode--noLabel c-1o67u6n">

                                                            <PhoneInput className="textTelInput__input textTelInput__input--noLabel textTelInput__input--margin" required type="tel" autocomplete="off"
                                                                international
                                                                countryCallingCodeEditable={false}
                                                                placeholder="e.g. 1234567890"
                                                                defaultCountry="IN"
                                                                value={value}
                                                                maxlength="16"
                                                                onChange={setValue} />
                                                        </span>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="c-pkit67">
                                                <button type="submit" value="submit" className="c-hptys5">Book Now</button>
                                            </div>
                                        </form>
                                        <div className="c-1yn8ri2">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* 
                                <div>
                                    <form action="" method="GET">
                                    </form>
                                    <div id="redirectionForm">
                                    </div>
                                </div>
                            */}
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-4">
                    <div>
                    <div className="c-1qpcdvy">
                        <div>
                            <div className="c-gg4vpm">
                                <div className="c-5hicrt">
                                    <div className="c-6a3y35">Flagship Library</div>
                                    <div className="c-zpzivj">
                                    </div>
                                    <div className="hotelRating">
                                        <div itemprop="aggregateRating" itemscope="true" itemtype="http://schema.org/AggregateRating" className="hotelRating__wrapper" tabindex="-1">
                                            <meta itemprop="ratingValue" content="4.5" />
                                            <span className="is-fontBold hotelRating__rating hotelRating__rating--excellent hotelRating__rating--clickable">
                                                <span>4.5</span>
                                                <span className="hotelRating__star">
                                                    <span lazyload="true" className="">
                                                        <svg viewBox="0 0 9 10" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M7.25 9.5a.238.238 0 01-.12-.032L4.5 7.948l-2.63 1.52a.238.238 0 01-.265-.017.271.271 0 01-.102-.26l.48-3.042-1.91-2.021a.276.276 0 01-.061-.268.255.255 0 01.197-.18l2.874-.508L4.276.646A.25.25 0 014.5.5c.094 0 .181.057.223.146l1.194 2.526 2.874.508a.255.255 0 01.197.18.275.275 0 01-.061.268l-1.91 2.021.48 3.042c.015.1-.024.201-.102.26a.242.242 0 01-.145.049z">
                                                            </path>
                                                        </svg>
                                                    </span>
                                                </span>
                                            </span>
                                            <meta itemprop="reviewCount" content="559" />
                                            <meta itemprop="bestRating" content="5" />
                                            <span className="hotelRating__ratingSummary  hotelRating__rating--clickable">(559 Ratings)</span>
                                            <span className="hotelRating__dot">·</span>
                                            <span className="hotelRating__ratingSummary">Excellent</span>
                                        </div>
                                    </div>
                                    <div className="c-sh83s0">Book Your Slot NOW!</div>
                                </div><div className="c-2clfy8">
                                    <div className="c-e2r6u2">
                                        <img alt="" loading="auto" importance="auto" src="./img/products/dcg.jpg" lazyload="true" className="c-2tglnv" />
                                    </div>
                                </div>
                            </div>
                            <div className="c-1six52r">
                                <div className="c-79elbk">
                                    <div className="c-1thcb3a c-9whsf3 c-gx0lhm c-g4aqpa">
                                        <div className="c-1pzykbe">
                                        <div className="datepickercustom">
                                        <div>
                                          <span id="shift">
                                            <select className="form-select-lg mb-3" id='shift' name='shift' aria-label=".form-select-lg example" required disabled>
                                              <option defaultValue>Shift</option>
                                              <option value="1">Morning</option>
                                              <option value="2">Afternoon</option>
                                              <option value="3">Evening</option>
                                              <option value="4">Night</option>
                                              <option value="5">Full Day</option>
                      
                                            </select>
                                          </span>
                      
                                          <span id="period">
                                            <select className="form-select form-select" id='period' name='period' aria-label=".form-select example" required disabled>
                                              <option defaultValue>Days</option>
                                              <option value="1">15 Days</option>
                                              <option value="2">1 months</option>
                                              <option value="3">2 months</option>
                                              <option value="4">3 Months</option>
                                              <option value="5">6 Months</option>
                                              <option value="6">12 Months</option>
                                            </select>
                                          </span>
                                        </div>
                      
                                      </div>
                                      <div><input className="datepickercustom" type="date"  value='' disabled /></div>
                      
                                            
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                                
                                {/*
                                    <div className="c-2pbfgc">
                                    <div className="c-1953uyt">
                                        <span className="c-1o86iao" isrtl="true">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                <path fill="black" fill-rule="nonzero" d="M4.756.968v.008c-.002 0 0-.002 0-.008zm0 18.55h-.951V.968A.96.96 0 0 1 4.755 0H15.22c.525 0 .95.444.95.968v18.544h-.951V.968c0 .008-10.464.008-10.464.008v18.542zm0-18.55l-.001.008.001-.008zm0 18.056H15.22V.968c0 .008-10.464.008-10.464.008v18.048zm-.951 0V.968A.96.96 0 0 1 4.755 0H15.22c.525 0 .95.444.95.968v18.056h3.353c.263 0 .476.219.476.488 0 .27-.213.488-.476.488H.476A.482.482 0 0 1 0 19.512c0-.27.213-.488.476-.488h3.329zm9.233-8.167s-.878-1.303-.878-1.8c0-.498.393-.9.878-.9a.89.89 0 0 1 .878.9c0 .497-.878 1.8-.878 1.8z" opacity=".3">
                                                </path>
                                            </svg>
                                        </span>
                                        <div>
                                            <span className="c-1w0k317">Classic  </span>
                                        </div>
                                    </div>
                                </div>
                                */}
                                <div className="c-1jbawm2">
                                    {/*
                                        <div className="c-18axi1k">
                                        <div className="c-6xf37g">Shift price for 2 months Guests</div>
                                        <div className="c-tvy9fi">₹11995</div>
                                    </div>
                                    <div className="c-18axi1k">
                                        <div className="c-6xf37g">Instant discount</div>
                                        <div className="c-tvy9fi">-₹5350</div>
                                    </div>
                                    <div className="c-18axi1k">
                                        <div className="c-6xf37g">55% Coupon Discount</div>
                                        <div className="c-tvy9fi">-₹3688</div>
                                    </div>
                                    <div className="c-18axi1k">
                                        <div className="c-6xf37g">Money Applied</div>
                                        <div className="c-tvy9fi">-₹591</div>
                                    </div>    
                                    */}

                                    <div className="c-bjdcis">
                                    </div>
                                    <div className="c-18axi1k">
                                        <div className="c-dgqod7">
                                            <span>Payable Amount</span>
                                            <div className="c-m8gzcb">inclusive of all taxes</div>
                                        </div>
                                        <div className="c-11tk0uk">₹600</div>
                                    </div>
                                </div>
                                <div className="c-1trve1z">
                                    <div className="c-1sugwtq">All staff vaccinated with 3rd dose</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

