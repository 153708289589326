import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useNavigate } from "react-router-dom"

export default function ForgotPassword() {
    const emailRef = useRef()
    const { resetPassword } = useAuth()
    const [error, setError] = useState("")
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setMessage("")
            setError("")
            setLoading(true)
            await resetPassword(emailRef.current.value)
            navigate('/update-profile')
        } catch {
            setError("Failed to reset password")
        }

        setLoading(false)
    }

    return (
        <div className='commonbox container'>
            <section id="header" className="d-flex align-items-center">
                <div className="container-fluid" style={{marginTop:'10vh'}}>
                    <div className='row'>
                        <div className="col-10 mx-auto">
                            <div className='row'>
                                <div className="col-lg-6 order-1 order-lg-1 d-flex justify-content-center flex-column header-img">
                                    <img src='/back.png' className="" alt="home img" />
                                </div>
                                <div className="col-lg-6 order-2 order-lg-2 d-flex justify-content-center flex-column ">
                                    <div className='sidecontent'>
                                        <img src='logobg.png' className="logoimage" alt="text img" />
                                        <h4 style={{ textAlign: "center" }}>FORGET PASSWORD</h4>
                                        <Card>
                                            <Card.Body>

                                                {error && <Alert variant="danger">{error}</Alert>}
                                                <Form onSubmit={handleSubmit}>
                                                    <Form.Group id="email">
                                                        <Form.Label className='inputlabel'>Enter Email</Form.Label>
                                                        <Form.Control className='inputbox' type="email" ref={emailRef} required placeholder='Example@rocket.mail' />
                                                    </Form.Group>
                                                    <Button disabled={loading} className="w-100 formbtn" type="submit">Send Confirmation code</Button>
                                                </Form>
                                                <div className="w-100 text-center mt-2">
                                                    Already have an account?  <Link to="/login" style={{ color: "#FFA500" }}><strong>Sign In</strong></Link>
                                                </div>
                                                <p style={{ textAlign: "center" }}>--------Or--------</p>
                                                <div className='text-center' style={{ alignItems: 'center' }}>
                                                    <button className="w-100 login-with-google-btn">Sign in with Google </button>
                                                </div>
                                               
                                            </Card.Body>
                                        </Card>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </section>

        </div>
    )
}
