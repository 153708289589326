import React, { useState } from "react";

export const Policies = (props) => {
    const [showMorePol, setShowMorePol] = useState(false);
    return (
        <div id="policies">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-md-8">
                        {" "}

                        <div className="description">
                            <div className="descriptionheader c-gcrtsc">Library policies</div>
                            <div className="policiespara">
                                <ol>
                                    <li>
                                        <p><strong>1. Respectful Behavior: </strong>In both in-person and electronic interactions, behave respectfully, courteously, and professionally to staff and your fellow members. Be respectful to others in your use of the Facilities. No discrimination, harassment, or hate speech shall be tolerated and will lead to immediate termination of your membership.</p>
                                    </li>
                                    <li>
                                        <p><strong>2. Courtesy of Self-study libraries: </strong>Keep noise levels to a respectful minimum for the comfort and enjoyment of those in the immediate area. No loud discussions, music, or other noise will be permitted in the open common areas without the explicit permission of READER'S REJOICE and of the members around you. Restore the common spaces to be as clean as or cleaner than when you found them. Help out if you see a mess that isn’t yours but needs cleaning.</p>
                                    </li>
                                    <li>
                                        <p><strong>3. Use of Equipment and Facilities: </strong>Use of the office equipment in the Facilities (the “Equipment”) is on a first-come, first-served basis unless otherwise scheduled. Do not remove any Equipment from the Facilities. Do not cause any damage to the Equipment or Facilities, excluding reasonable wear and tear. Do not use the Facilities for any unlawful, obscene, or objectionable purpose, or in a way that infringes on the intellectual property of third parties. Objectionable purposes will be determined at the discretion of the Company. All members are responsible for their own belongings and actions while using the Facilities.</p>
                                    </li>
                                    <li>
                                        <p><strong>4. Recovery of loss: </strong>If any library member found guilty of creating noise, ruckus or damaging the property shall be terminated membership of the member. </p>
                                    </li>
                                    <li>
                                        <p><strong>5. Guests: </strong>Non-member guests (“Guests”) may be permitted into the Facilities, as long as the Guest is accompanied by a member at all times. Guests are not permitted to use the Equipment and must not interfere with other members. Guests and their actions are the sole responsibility of the host member and READER'S REJOICE. READER'S REJOICE reserves the right to refuse access to any non-member guests without notice or explanation.</p>
                                    </li>
                                    <li>
                                        <p><strong>6. Internet Usage: </strong>No spamming, posting, or downloading files that you know or should know are illegal or that you have no rights to. You may provide your Guests access to the Guest internet network. Do not give access to the Facilities’ Member internet to any Guest or other person unless you get permission from the READER'S REJOICE. The internet is shared by all members, so respect bandwidth and avoid streaming of audio or video when possible.</p>
                                    </li>
                                    <li>
                                        <p><strong>7. Recovery of loss: </strong>Staffed hours for the Facilities are 8AM to 8PM, Monday to Sunday,excluding national holidays. Members may have access to the Facilities during unstaffed hours. Members using the Facilities during unstaffed hours assume all risks associated with using the Facilities and Equipment alone without the aid and presence of staff on the premises. </p>
                                    </li>
                                    <li>
                                        <p><strong>8. Report of Accident: </strong>You must report to the READER'S REJOICE patron any incident, accident, or injury that occurs in the Facilities immediately or as soon as it is safe to do so. </p>
                                    </li>
                                    <li>
                                        <p><strong>9. Membership cancellation: </strong>If you do not follow the rules and regulations of READER'S REJOICE and found guilty READER'S REJOICE has a immediate right to cancel your membership and due to various circumstance READER'S REJOICE will terminate your membership. </p>
                                    </li>
                                </ol>
                                {/* 
                                <ul>
                                    {props.data
                                        ? props.data.map((d, i) => (
                                            <div key={`${d.list1}-${i}`} className=''>
                                                {' '}
                                                <li>&#x2022; &nbsp;
                                                    {d.list1}
                                                </li>

                                            </div>
                                        ))
                                        : 'loading'}

                                </ul>
                                */}
                                <div className="collapse" id="collapsePolicies">
                                    <div className="card card-body">
                                        <ol>
                                            <li>
                                                <p><strong>10. Refund and Cancellation: </strong>Please read the payment terms and conditions carefully before payment to any of the booking plans, once you have booked you cannot change, but you can cancel your booking plan within 7 days from your check-in date and the first day of check-in till 10 AM, 10% cancellation charges will be deducted. If you wish to claim a refund you have an option to raise the request within 7 days from your check-in date and the first day of check-in till 10 AM . After check-in from the first day READER'S REJOICE will not provide any refund to the customers. Once you Book any plan and make the required payment, it shall be final and there cannot be any changes or modifications of the Date’s. But can change a regular time schedule. </p>
                                            </li>
                                            <li>
                                                <p><strong>11. How to Cancel: </strong>You can cancel your booking on any of our platforms Mobile App or Website.</p>
                                            </li>
                                            <li>
                                                <p><strong>12. 24/7 Customer Support Chat: </strong>refund@readersrejoice.in <br/>The applicable refund amount will be credited to you within 7-14 business days. </p>
                                            </li>
                                            <li>
                                                <p><strong>13. Customer check-in and check-out policy: </strong>READER'S REJOICE’s customer can check-in any time in the day time, READER'S REJOICE follows standard check-in from 7AM to 10PM. Most of the READER'S REJOICE patrons follow the 24 hours check-in and check-out, it depends on the time schedule of the patron’s run time of the services and a customer can check-in within the time schedule he/she choose at the time of booking any plan, (For example-if a customer choose 7AM to 12PM time schedule so he/she can check-in within the time he/she choose) Book any of the plan and within the time schedule he/she choose, he/she can check-in or check-out within the time schedule of the library. According to the time schedule a customer can check-in and check-out. As per READER'S REJOICE’s patron, customer of OLIB can check-in in a standard time the patron of READER'S REJOICE offers. If a customer found guilty to exceed the time schedule within the time period he/she choose at the time of booking, READER'S REJOICE has the authority to make any penalty to the customer . READER'S REJOICE has the authority to cancel your membership, if you found guilty. Don’t Overuse Services or Amenities.<br/>It can create extra problems and distractions for the members inside self-study center when someone oversteps their boundaries. Remember to practice etiquette rules and only use your allocated desk, use the discussion room for the time you actually need it and stay within the desk you rented.</p>
                                            </li>
                                            <li>
                                                <p><strong>14. Sanitize Your self-study library: </strong>Your desk is the place you will spend the most amount of time while in the library. For this reason, you want to make sure that it’s always clean, and by this, I don’t mean just organized. Making sure your study space is disinfected regularly is something you should consider including in your daily work schedule. A good idea is to sanitize the area either right before you leave work or first thing in the morning to ensure that you always start the day fresh and clean. It’s best to clean your desk in the morning, or whenever you arrive, to ensure it is sanitized.</p>
                                            </li>
                                            <li>
                                                <p><strong>15. No Meetings in the Desk Area: </strong>While also a good idea to avoid disturbing those who have desks around you, if you have something to discuss with co-members, try to move either to a discussion room or outside of premises. While you will more than likely have to wear masks within the co-study space, having a bunch of people crowding around a small desk can be risky and hard to manage while also keeping the social distancing standards in place.</p>
                                            </li>
                                            <li>
                                                <p><strong>16.  Safety and Security: </strong></p>
                                                <p>1. For your own safety, please follow fire safety and emergency response procedures as directed by library staff.</p>
                                                <p>2. READER'S REJOICE/Property owner are not liable for lost, misplaced, damaged or stolen valuables or belongings.</p>
                                                <p>3. Keeping your safety in mind, if you found any suspicious activities in library immediately call library staff and inform READER'S REJOICE team.</p>
                                            </li>
                                            <li>
                                                <p><strong>17. Beware of Fraud / Theft or loss: </strong>READER'S REJOICE does not authorized any of its employees, consultants, third-party vendors, associates to collect payment in any other form other than payments from secure READER'S REJOICE.</p>
                                                <p>1. Any instances where collection of payment is attempted from any unauthorized UPI payment other than OLIB are acts of fraud. Encountering and acting on the same is solely on your own accord and discretion. READER'S REJOICE will not be responsible for any loss/liability arising out of such an event.</p>
                                                <p>2. You are solely responsible for your essentials, devices and goods which you bring with you, READER'S REJOICE and staff are not responsible for that, take care of your things by yours own.</p>
                                            </li>
                                            <li>
                                                <p><strong>18. Chewing Ghutka or Tobacco, Smoking, Drugs and Alcohol:</strong></p>
                                                <p>1. Chewing Ghutka or tobacco is prohibited inside library and in campus/Building of Library.</p>
                                                <p>2. Smoking is prohibited inside library and in campus/Building of Library.</p>
                                                <p>3. Drinking alcohol is prohibited in inside library and in campus/Building of Library.</p>
                                                <p>4. Anyone found using or under the influence of illegal drugs or substances classified under the Narcotic Drugs and Psychotropic Substances Act, 1985 will be reported to the police and asked to leave the library. Any evidence or suspicion of drug use at the library will also be reported immediately to the police.</p>
                                            </li>
                                            <li>
                                                <p><strong>19. Prohibition: </strong>Library members are not permitted to eat food or any beverages on the desk, READER'S REJOICE patrons provided the separate lunch area/canteen.</p>
                                            </li>
                                        </ol>
                                    {/*     
                                    <ul>

                                            {props.data
                                                ? props.data.map((d, i) => (
                                                    <div key={`${d.list2}-${i}`} className=''>
                                                        {' '}
                                                        <li>&#x2022; &nbsp;
                                                            {d.list2}
                                                        </li>

                                                    </div>
                                                ))
                                                : 'loading'}

                                        </ul>
                                        */}
                                    </div>
                                </div>

                                <button className="btn btn-whitebutton" type="button" data-toggle="collapse" data-target="#collapsePolicies" aria-expanded="false" aria-controls="collapsePolicies"
                                    onClick={() => setShowMorePol(!showMorePol)}>
                                    {showMorePol ? "Show less" : "Show more"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
