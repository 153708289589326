import { useState, useEffect } from "react";
import { Navigation } from "../navigation";
import { Header } from "../header";

import { About } from "../about";


import { Testimonials } from "../testimonials";

import { Contact } from "../contact";
import { Products } from "../branchnav/Products"
import { Services } from "../services";
import JsonData from "../../data/data.json";
import SmoothScroll from "smooth-scroll";


import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Gallary2 from '../Gallary2';



import { Footer2 } from "../footer2";
import { About2 } from "../about2";
import Crous2 from "../crous2"

import { Crousl } from "../crousl";
import { Aminities } from "../amities";
import { Helmet } from "react-helmet";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const Home = () => {
  const articleSchema = {
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": "Readers Rejoice Library",
    "author": {
      "@type": "Person",
      "name": "Wappotix"
    },
    "datePublished": "2024-09-01",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "http://readersrejoice.in"
    },
    "articleBody": "Best Library in Bihar"
  };
  const [landingPageData, setLandingPageData] = useState({});
  const [showButton, setShowButton] = useState(true);
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Best Library / Reading Room / Self Study Center in Patna ( Boring Canal Road | Kankarbagh | Kanti Factory Road ) Call @ 8002417417</title>
        <meta keyword="Best Library in Patna" />
        <meta name="keywords" content="Library Nearby, Best Library in Patna, Cheap and Best Libaray Near me, Best Self Study Center nearby, Best Reading Room Nearby, Free Books Library in Patna" />
        <meta name="description" content="Reader's Rejoice - पढ़ो और पढ़ने दो !! 24/7 Library / Self Study Reading Room  having 4 branches in different prime location in patna. 1. Libary in East Boring Canal Road 2. Library in West Boring Canal Road 3. Libary in Kankarbagh 4. Library in Kanti Factory Road with all the modern amanities such as Peaceful & Study Atmosphere, Seperate Cabin for Self Study, Separate Discussion Room, Separate Cabin Light, Separate charging point, Fully Air-conditioned Study hall, High Speed WiFi, RO Drinking Water with Hot & Cold water facility, Competitive Exams Books, Monthly Magazines, Daily Newspaper, Locker, CCTV Surveillance, Power Backups, Parking Facility" />
        <meta name="author" content="Reader's Rejoice Library / Reading Room / Self Study Center" />
      </Helmet>
      <script type="application/ld+json">
        {JSON.stringify(articleSchema)}
      </script>
      <div className='social'>
        <div>
          <a className="whatsapp" href="https://api.whatsapp.com/send?phone=918002417417" target="_blank">
            <i className="fa fa-whatsapp"></i>
          </a>
        </div>
        <div>
          <a className="phone" href="tel: 8002417417">
            <i className="fa fa-phone"></i>
          </a>
        </div>
      </div>

      <Products data={landingPageData.Products} />

      <About data={landingPageData.About} />
      <Services data={landingPageData.Services} />
      <Gallary2 />


      <Testimonials data={landingPageData.Testimonials} />

      <Contact data={landingPageData.Contact} />



      {/*<Attendence /> <Filtergallery />
  <Attendenceyup />  <Crousl /> <About2 data={landingPageData.About} />*/}
      {/*<Attendence2 /> <Footer2 data={landingPageData.Footer} /> */}
      {/*<Attendencenew /> */}


      {/*<Logindivide /> */}

    </div>
  );
};

export default Home;

/*  
  <Products data={landingPageData.Products}/>
*/
