import React from 'react';
import Card from './Card';

export const Products = (props) => {
  return (
    <div id="product" className='text-center'>
    <div className='container'>
      <div className="my-5">
        <h2 className="text-center"> Please Select Branch </h2>
      </div>
      <div className="container-fluid mb-5" >
        <div className='row'>
          <div className="col-10 mx-auto">
            <div className="row gy-3 img-fluid">
              {props.data
                ? props.data.map((d, i) => {
                  return <Card key={i}
                    imgsrc={d.imgsrc}
                    title={d.title}
                    detail={d.detail}
                    
                    icon1={d.icon1}
                    icon2={d.icon2}
                    icon3={d.icon3}
                    icon4={d.icon4}
                    Moredetail={d.Moredetails}
                    url={d.url}
                  />;
                })
                : 'Loading...'
              }
              
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  )
}

/*detail={d.detail}  =>for detail */

