import { useState, useEffect } from "react";

import JsonData from "../../data/data.json";
import SmoothScroll from "smooth-scroll";
import Crous2 from "../crous2"

import { Crousl } from "../crousl";
import { Aminities } from "../amities";
import { Aminities2 } from "../pages/amities2";
import { Policies } from "../pages/policies";
import { Rightfiedcontainer } from "../rightfiedcontainer";
import { Aminities3 } from "../pages/amities3";
import { Helmet } from "react-helmet";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const Chome = () => {
  const articleSchema = {
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": "Readers Rejoice Library in patna",
    "author": {
      "@type": "Person",
      "name": "Wappotix"
    },
    "datePublished": "2024-09-01",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "http://readersrejoice.in/chome"
    },
    "articleBody": "Best Library in bihar"
  };
  const [landingPageData, setLandingPageData] = useState({});
  const [showButton, setShowButton] = useState(true);
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  return (
    <div>
      <Helmet>
        <title>Best Library / Reading Room / Self Study Center in Patna ( Boring Canal Road | Kankarbagh | Kanti Factory Road ) Call @ 8002417417</title>
        <meta keyword="Best Library in Patna" />
        <meta name="keywords" content="Library Nearby, Best Library in Patna, Cheap and Best Libaray Near me, Best Self Study Center nearby, Best Reading Room Nearby, Free Books Library in Patna" />
        <meta name="description" content="Reader's Rejoice - पढ़ो और पढ़ने दो !! 24/7 Library / Self Study Reading Room  having 4 branches in different prime location in patna. 1. Libary in East Boring Canal 5Road 2. Library in West Boring Canal Road 3. Libary in Kankarbagh 4. Library in Kanti Factory Road with all the modern amanities such as Peaceful & Study Atmosphere, Seperate Cabin for Self Study, Separate Discussion Room, Separate Cabin Light, Separate charging point, Fully Air-conditioned Study hall, High Speed WiFi, RO Drinking Water with Hot & Cold water facility, Competitive Exams Books, Monthly Magazines, Daily Newspaper, Locker, CCTV Surveillance, Power Backups, Parking Facility" />
        <meta name="author" content="Reader's Rejoice Library / Reading Room / Self Study Center" />
      </Helmet>
      <script type="application/ld+json">
        {JSON.stringify(articleSchema)}
      </script>
      <div className='social'>
        <div>
          <a className="whatsapp" href="https://api.whatsapp.com/send?phone=918002417417" target="_blank">
            <i className="fa fa-whatsapp"></i>
          </a>
        </div>
        <div>
          <a className="phone" href="tel: 8002417417">
            <i className="fa fa-phone"></i>
          </a>
        </div>
      </div>

      <Crous2 data={landingPageData.Crous2} />
      <Aminities2 data={landingPageData.Headerebcr} />

      {/*<Aminities2 data={landingPageData.Aminities} />
      <Rightfiedcontainer />
      <Aminities data={landingPageData.Aminities} /> */}
      <Policies data={landingPageData.Policies} />

    </div>
  )
}

export default Chome;