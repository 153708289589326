//import firebase from "firebase/app"
import "firebase/auth"
import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"

const app = firebase.initializeApp({
    apiKey: "AIzaSyCuev0Ww8e9ryfGXYpcmxKfzMKvvensXGs",
    authDomain: "learnnet-development.firebaseapp.com",
    projectId: "learnnet-development",
    storageBucket: "learnnet-development.appspot.com",
    messagingSenderId: "978803556765",
    appId: "1:978803556765:web:b96947fb8b9276368bef74"
})

export const auth = app.auth()
export default app
