import { Link} from 'react-router-dom';
import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { useNavigate } from "react-router-dom"






export default function Register() {
    const emailRef = useRef()
    const passwordRef = useRef()
    const passwordConfirmRef = useRef()
    const { signup } = useAuth()
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();


    async function handleSubmit(e) {
        e.preventDefault()

        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError("Passwords do not match")
        }

        try {
            setError("")
            setLoading(true)
            await signup(emailRef.current.value, passwordRef.current.value)
            navigate("/dash")

        } catch {
            setError("Failed to create an account")
        }

        setLoading(false)
    }

    return (
        <div className='commonbox container'>
            <section id="header" className="d-flex align-items-center">
                <div className="container-fluid" style={{ marginTop: '20vh' }}>
                    <div className='row'>
                        <div className="col-10 mx-auto">
                            <div className='row'>
                                <div className="col-lg-6 order-1 order-lg-1 d-flex justify-content-center flex-column header-img">
                                    <img src='/back.png' className="" alt="home img" />
                                </div>
                                <div className="col-lg-6 order-2 order-lg-2 d-flex justify-content-center flex-column ">
                                    <div className='sidecontent'>
                                        <img src='logobg.png' className="logoimage" alt="text img" />
                                        <h4 style={{ textAlign: "center" }}>Registration </h4>
                                        <Card>
                                            <Card.Body>

                                                {error && <Alert variant="danger">{error}</Alert>}
                                                <Form onSubmit={handleSubmit}>
                                                    <div id="fullname">
                                                        <div className="form-row">
                                                            <div className="form-group col-md-4">
                                                                <label for="fname">First Name</label>
                                                                <input type="text" className="form-control" id="fname" placeholder="Firstname" required />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label for="mname">Middle Name</label>
                                                                <input type="text" className="form-control" id="mname" placeholder="Middlename" />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label for="lname">Last Name</label>
                                                                <input type="text" className="form-control" id="lname" placeholder="Lastname" required />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-row">
                                                        <div className="form-group col-md-6" id="parent">
                                                            <label for="parent"></label>
                                                            <input type="text" className="form-control" id="parent" placeholder="Parent Name" required />
                                                        </div>
                                                        <div className="form-group col-md-6" id="relation">
                                                            <label for="relation"></label>
                                                            <input type="text" className="form-control" id="relation" placeholder="Relation" required/>
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                    <div class="form-group" id="Adress">
                                                        <label for="Adress"></label>
                                                        <input type="text" class="form-control" id="Adress" placeholder=" CurrentAddres" />
                                                    </div>
                                                    </div>

                                                    <div id="addrress2">
                                                        <div className="form-row">
                                                            <div className="form-group col-md-4">
                                                                <label for="city"></label>
                                                                <input type="text" className="form-control" id="city" placeholder="City" required />
                                                                <div className="invalid-feedback">
                                                                    Please provide a valid city.
                                                                </div>
                                                            </div>

                                                            <div className="form-group col-md-4">
                                                                <label for="state" className="form-label"></label>
                                                                <select className="form-select" id="validationCustom04" required>
                                                                    <option selected disabled value="">Choose...</option>
                                                                    <option>...</option>
                                                                </select>
                                                                <div className="invalid-feedback">
                                                                    Please select a valid state.
                                                                </div>
                                                            </div>

                                                            <div className="form-group col-md-4">
                                                                <label for="zip"></label>
                                                                <input type="number" className="form-control" id="zip" placeholder="Zip Code" required />
                                                                <div className="invalid-feedback">
                                                                    Please provide valid Pincode.
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    
                                                    <div className=''>
                                                        <Form.Group id="email">
                                                            <Form.Label className='inputlabel'></Form.Label>
                                                            <Form.Control className='inputbox' type="email" ref={emailRef} required placeholder='Email' />
                                                        </Form.Group>
                                                    </div>


                                                    <div className=''>
                                                        <Form.Group id="dob">
                                                            <Form.Label className='inputlabel'>DOB</Form.Label>
                                                            <Form.Control className='inputbox' type="date" required placeholder='DOB' />
                                                        </Form.Group>
                                                    </div>

                                                    <div className='row'>
                                                        <div className='col'>
                                                            <Form.Group id="phone">
                                                                <Form.Label className='inputlabel'></Form.Label>
                                                                <Form.Control className='inputbox' type="number" required placeholder='Phone No' />
                                                            </Form.Group>
                                                        </div>
                                                        <div className='col'>
                                                            <Form.Group id="altphone">
                                                                <Form.Label className='inputlabel'></Form.Label>
                                                                <Form.Control className='inputbox' type="number" placeholder='Alternet No' />
                                                            </Form.Group>
                                                        </div>

                                                        <div className='row'>
                                                            <div className='col'>
                                                                <label for="validationCustom04" className="form-label">Category</label>
                                                                <select className="form-select" id="validationCustom04" required>
                                                                    <option selected disabled value="">Choose...</option>

                                                                    <option value="1">Student</option>
                                                                    <option value="2">Employed</option>
                                                                    <option value="3">Retired</option>
                                                                    <option value="4">Housewife</option>
                                                                    <option value="5">Other</option>
                                                                </select>
                                                            </div>
                                                            <div className='col'>
                                                                <Form.Group id="qualification">
                                                                    <Form.Label className='inputlabel'>Preparing</Form.Label>
                                                                    <Form.Control className='inputbox' type="text" placeholder='Qualification' />
                                                                </Form.Group>
                                                            </div>
                                                        </div><br />
                                                        <div>
                                                            <p>Preferred Language in which you want Newspaper/ Magazine/ Novels / Academic Books / Competitive Exam Books in the Library:</p>
                                                        </div>
                                                        <div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked />
                                                                <label className="form-check-label" for="flexRadioDefault1">
                                                                    English
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                                                <label className="form-check-label" for="flexRadioDefault2">
                                                                    Hindi
                                                                </label>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <Button disabled={loading} className="w-100 formbtn" type="submit">Register Now</Button>
                                                </Form>
                                                <div className="w-100 text-center mt-2">
                                                    Already have an account?  <Link to="/login" style={{ color: "#FFA500" }}><strong>Sign In</strong></Link>
                                                </div>


                                            </Card.Body>
                                        </Card>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </section>

        </div>
    )
}
