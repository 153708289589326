import JsonData from "../data/data.json";
export const About = (props) => {
  
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <div data-aos="flip-left">
              <img src="img/about.jpeg" style={{marginTop:'50px'}} className="img-responsive" alt="about" />{" "}
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About Us</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              
              <img src={props.data ? props.data.imgshift : "loading..."} style={{width:'100%'}} className="img-responsive" alt="about" />{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
