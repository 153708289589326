import React from "react";
var d = new Date();
var currYear = d.getFullYear();

export const Footer2 = (props) => {
    return (
        <div style={{ backgroundColor: 'fff' }} >
            <div id='contact'>
                <div className='container col-md-12'>
                    <div className='row'>
                        <div className='social col-lg-8 col-md-8 col-sm-6 col-xs-12'>
                            <ul>
                                <li>
                                    <a href={props.data ? props.data.facebook : '/'} target="newtab" >
                                        <i className='fa fa-facebook'></i>
                                    </a>
                                </li>

                                <li>
                                    <a href={props.data ? props.data.youtube : '/'} target="newtab" >
                                        <i className='fa fa-youtube'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href={props.data ? props.data.instagram : '/'} target="newtab" >
                                        <i className='fa fa-instagram'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href={props.data ? props.data.twitter : '/'} target="newtab" >
                                        <i className='fa fa-twitter'></i>
                                    </a>
                                </li>
                            </ul>
                            <div id='footer'>
                                <div className='text-center'>
                                    <p>
                                        Copyright  {currYear}. All rights reserved &copy; <a href='/'>Readers Rejoice.</a> Design by{' '}
                                        <a href='https://wappotix.com/' target="newtab" rel='nofollow'>
                                            Wappotix Pvt. Ltd.
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <ul className='contact-item'>
                            <h4 className='page-scroll' style={{color: '#ec5818',marginTop: '100px'}}><strong>UseFul Links</strong></h4>
                                <li>
                                    <a href='/' className='page-scroll'>
                                        <i className="fa fa-commenting-o"> FAQ</i>
                                    </a>
                                </li>
                                <li>
                                    <a href='/' className='page-scroll' target="newtab" rel='nofollow'>
                                        <i className="fa fa-file-text-o"> terms and condition</i>
                                    </a>
                                </li>
                                <li>
                                    <h4 className='page-scroll' style={{color: '#ec5818'}}>
                                        <strong> &nbsp; Support </strong>
                                    </h4>
                                </li>
                                <li>
                                    <a href="tel: 8002417417" className='page-scroll'>
                                        <i className="fa fa-phone"> Call Us</i>
                                    </a>
                                </li>
                                <li>
                                    <a href="mailto: support@redersrejoice.in" className='page-scroll'>
                                        <i className='fa fa-envelope-o'> Mail Us</i>
                                    </a>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
}


