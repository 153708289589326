import React, { useState, useEffect } from "react"
import { Card, Button, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useNavigate } from "react-router-dom"

export default function Dashboard() {
  const [error, setError] = useState("")
  const { currentUser, logout } = useAuth()
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/")
    }, 5000);
  });
  async function handleLogout() {
    setError("")

    try {
      await logout()
      navigate("/login")
    } catch {
      setError("Failed to log out")
    }
  }
  
  return (
    <div className='commonbox container'>
            <section id="header" className="d-flex align-items-center">
                <div className="container-fluid" style={{marginTop:'10vh'}}>
                    <div className='row'>
                        <div className="col-10 mx-auto">
                            <div className='row'>
                                <div className="col-lg-6 order-1 order-lg-1 d-flex justify-content-center flex-column header-img">
                                    <img src='/back.png' className="" alt="home img" />
                                </div>
                                <div className="col-lg-6 order-2 order-lg-2 d-flex justify-content-center flex-column ">
                                    <div className='sidecontent'>
                                        <img src='logobg.png' className="logoimage" alt="text img" />
                                        <h4 style={{ textAlign : "center" }}>PROFILE</h4>
                                        <Card>
                                            <Card.Body>
                                               
                                                {error && <Alert variant="danger">{error}</Alert>}
                                                <div style={{ textAlign: "center" }}>
                                                    <strong>Hello,</strong> {currentUser.email} <strong>Welcome</strong><br/><br/>
                                                </div>
                                                <div className="w-100 text-center mt-2">
                                                <Button variant="link" style={{ textAlign : "center" }} onClick={handleLogout}>
                                                  Log Out
                                                </Button>
                                              </div>
                                                
                                            </Card.Body>
                                        </Card>
                                        
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </section>

        </div>
  )
}
