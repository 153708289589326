import { NavLink } from 'react-router-dom';
import React, { useRef, useState, useEffect } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useNavigate } from "react-router-dom"

export default function Login() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const { login } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
}, []);
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setError("")
      setLoading(true)
      await login(emailRef.current.value, passwordRef.current.value)
      navigate("/dash")
    } catch {
      setError("Failed to log in")
    }

    setLoading(false)
  }

  return (
    <div className='commonbox container'>
      <section id="header" className="d-flex align-items-center">
        <div className="container-fluid" style={{marginTop:'10vh'}}>
          <div className='row'>
            <div className="col-10 mx-auto">
              <div className='row'>
                <div className="col-lg-6 order-1 order-lg-1 d-flex justify-content-center flex-column header-img">
                  <img src='back.png' className="" alt="home img" />
                </div>
                <div className="col-lg-6 order-2 order-lg-2 d-flex justify-content-center flex-column ">
                  <div className='sidecontent'>
                    <img src='logobg.png' className="logoimage" alt="text img" />
                    <h4 style={{ textAlign: "center" }}>SIGN IN</h4>
                    <Card>
                      <Card.Body>

                        {error && <Alert variant="danger">{error}</Alert>}
                        <Form onSubmit={handleSubmit}>
                          <Form.Group id="email">
                            <Form.Label className='inputlabel'>Email</Form.Label>
                            <Form.Control className='inputbox' type="email" ref={emailRef} required placeholder='Example@rocket.mail' />
                          </Form.Group>
                          <Form.Group id="password">
                            <Form.Label className='inputlabel'>Password</Form.Label>
                            <Form.Control className='inputbox' type="password" ref={passwordRef} required />
                          </Form.Group>
                          <div className="w-100 text-center mt-3">
                            <Link to="/forgot-password" style={{ color: "#FFA500" }}><strong>Forgot Password?</strong></Link>
                          </div>
                          <Button disabled={loading} className="w-100 formbtn" type="submit">Login</Button>
                        </Form>
                        <div className="w-100 text-center mt-2">
                          Are you new here?  <Link to="/signup" style={{ color: "#FFA500" }}><strong>Sign up</strong></Link>
                        </div>
                        <p style={{ textAlign: "center" }}>--------Or--------</p>
                        <div className='text-center' style={{ align: 'center' }}>
                          <button className="w-100 login-with-google-btn">Sign in with Google </button>
                        </div>
                        
                      </Card.Body>
                    </Card>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </section>

    </div>

  )
}
