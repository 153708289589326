
import { useState, useEffect } from 'react';

import { connect } from 'react-redux'

export const Header = (props) => {

  return (
    <header id='header'>
      <div className={props.data ? props.data.back : "loading..."}>{" "}
        <div className='intro overlay'>
          <div className='container'>
            <div>
              <div className='row'>
                <div className='col-md-8 col-md-offset-2 intro-text'>
                  <h1>
                    {props.data ? props.data.title : 'Loading'}
                    <span></span>
                  </h1>
                  <p>{props.data ? props.data.paragraph : 'Loading'}</p>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
