import React, { useState, useEffect } from 'react'; 
import emailjs from 'emailjs-com';

export const LibSearch = (props) => { 
    return ( 
    <div id="search" className='libsearch' style={{marginTop:'20vh'}}>
    <div className="listing__topSection">
      {/*
      <aside className="sidebar">
               <div className="sidebar__header">
                  <div className="filters__heading"><span>Filters</span></div>
                  <a tabindex="0" role="button" className="sidebar__clearAll"><span>Clear All</span></a>
               </div>
               <div className="filters">
                  <div className="filters__wrapper">
                     <div className="filters__item">
                        <section>
                           <h4>Popular locations in Patna,Bihar,India</h4>
                           <div>
                              <div className="autocomplete__wrapper undefined"><input className="autocomplete__input filter__input" type="text" placeholder="Search.."></div>
                           </div>
                           <div className="TileGroup">
                              <div className="TileGroupWrapper">
                                 <div className="Tile  ">Railway Station</div>
                                 <div className="Tile  ">Patna Junction</div>
                                 <div className="Tile  ">Boring Road</div>
                                 <div className="Tile  ">Rajendra Nagar</div>
                                 <div className="Tile  ">Danapur</div>
                                 <div className="Tile is-hidden">Hotel Patliputra Nirvana</div>
                                 <div className="Tile is-hidden">Bailey Road</div>
                                 <div className="Tile is-hidden">Raja Bazar</div>
                                 <div className="Tile is-hidden">Hotel Chanakya Inn</div>
                                 <div className="Tile is-hidden">Kurji</div>
                                 <div className="Tile is-hidden">Patliputra Colony</div>
                                 <div className="Tile is-hidden">Ashok Rajpath Road</div>
                                 <div className="Tile is-hidden">Jay Prakash Narayan International Airport</div>
                                 <div className="Tile is-hidden">Gandhi Maidan Road</div>
                                 <div className="Tile is-hidden">New Dak Bunglow Road</div>
                                 <div className="Tile is-hidden">Kumhrar</div>
                                 <div className="Tile is-hidden">AIIMS Patna Helipad</div>
                                 <div className="Tile is-hidden">Mokama</div>
                                 <div className="Tile is-hidden">Bypass Road</div>
                                 <div className="Tile is-hidden">West Boring Canal Road</div>
                              </div>
                              <span tabindex="0" role="button" className="TileGroup__viewMore">+ View More</span>
                           </div>
                        </section>
                     </div>
                  </div>
                  <div className="filters__wrapper">
                     <div className="filters__item">
                        <div className="rangepicker">
                           <h4>Price</h4>
                           <div aria-disabled="false" className="input-range">
                              <span className="input-range__label input-range__label--min"><span className="input-range__label-container">₹479</span></span>
                              <div className="input-range__track input-range__track--background">
                                 <div className="input-range__track input-range__track--active" style="left: 0%; width: 100%;"></div>
                                 <span className="input-range__slider-container" style="position: absolute; left: 0%;">
                                    <span className="input-range__label input-range__label--value"><span className="input-range__label-container">₹479</span></span>
                                    <div aria-valuemax="1601" aria-valuemin="479" aria-valuenow="479" className="input-range__slider" draggable="true" role="slider" tabindex="0"></div>
                                 </span>
                                 <span className="input-range__slider-container" style="position: absolute; left: 100%;">
                                    <span className="input-range__label input-range__label--value"><span className="input-range__label-container">₹1601</span></span>
                                    <div aria-valuemax="1601" aria-valuemin="479" aria-valuenow="1601" className="input-range__slider" draggable="true" role="slider" tabindex="0"></div>
                                 </span>
                              </div>
                              <span className="input-range__label input-range__label--max"><span className="input-range__label-container">₹1601</span></span>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="filters__wrapper">
                     <div className="filters__item">
                        <div className="checkBoxGroup">
                           <h4>Collections</h4>
                           <label className="checkbox  " htmlfor="android-app-family-collection">
                              <input className=" " id="android-app-family-collection" name="android-app-family-collection" count="7" priority="1.7976931348623157E308" filtered_count="7" hotel_count="7" label="Family OYOs" data-state="Family OYOs" excludequery="true" extraprops="[object Object]" type="checkbox">
                              <span lazyload="true" className="checkbox__checkmark checkbox__checkmark--  c-1m1i8cc">
                                 
                                 <svg viewBox="0 0 12 9" version="1.1" id="checkmark" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g id="Arash" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                       <g id="Listing_ListView" transform="translate(-22.000000, -260.000000)" fill="white">
                                          <g id="component/filters" transform="translate(0.000000, 96.000000)">
                                             <g id="ic/checkbox/inactive-copy-4" transform="translate(20.000000, 160.000000)">
                                                <polygon id="Fill-3-Copy-9" points="2 8.68138196 3.20857143 7.46353167 6.28571429 10.5556622 12.7914286 4 14 5.22648752 6.28571429 13"></polygon>
                                             </g>
                                          </g>
                                       </g>
                                    </g>
                                 </svg>
                              </span>
                              <div className="checkbox__label">Family OYOs</div>
                           </label>
                           <label className="checkbox  " htmlfor="android-app-frontier-collection">
                              <input className=" " id="android-app-frontier-collection" name="android-app-frontier-collection" count="1" priority="1.7976931348623157E308" filtered_count="1" hotel_count="1" label="For Group Travellers" data-state="For Group Travellers" excludequery="true" extraprops="[object Object]" type="checkbox">
                              <span lazyload="true" className="checkbox__checkmark checkbox__checkmark--  c-1m1i8cc">
                                 
                                 <svg viewBox="0 0 12 9" version="1.1" id="checkmark" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g id="Arash" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                       <g id="Listing_ListView" transform="translate(-22.000000, -260.000000)" fill="white">
                                          <g id="component/filters" transform="translate(0.000000, 96.000000)">
                                             <g id="ic/checkbox/inactive-copy-4" transform="translate(20.000000, 160.000000)">
                                                <polygon id="Fill-3-Copy-9" points="2 8.68138196 3.20857143 7.46353167 6.28571429 10.5556622 12.7914286 4 14 5.22648752 6.28571429 13"></polygon>
                                             </g>
                                          </g>
                                       </g>
                                    </g>
                                 </svg>
                              </span>
                              <div className="checkbox__label">For Group Travellers</div>
                           </label>
                           <label className="checkbox  " htmlfor="android-app-localite-collection">
                              <input className=" " id="android-app-localite-collection" name="android-app-localite-collection" count="22" priority="1.7976931348623157E308" filtered_count="22" hotel_count="22" label="Local IDs accepted" data-state="Local IDs accepted" excludequery="true" extraprops="[object Object]" type="checkbox">
                              <span lazyload="true" className="checkbox__checkmark checkbox__checkmark--  c-1m1i8cc">
                                 
                                 <svg viewBox="0 0 12 9" version="1.1" id="checkmark" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g id="Arash" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                       <g id="Listing_ListView" transform="translate(-22.000000, -260.000000)" fill="white">
                                          <g id="component/filters" transform="translate(0.000000, 96.000000)">
                                             <g id="ic/checkbox/inactive-copy-4" transform="translate(20.000000, 160.000000)">
                                                <polygon id="Fill-3-Copy-9" points="2 8.68138196 3.20857143 7.46353167 6.28571429 10.5556622 12.7914286 4 14 5.22648752 6.28571429 13"></polygon>
                                             </g>
                                          </g>
                                       </g>
                                    </g>
                                 </svg>
                              </span>
                              <div className="checkbox__label">Local IDs accepted</div>
                           </label>
                           <label className="checkbox  " htmlfor="android-app-couple-collection">
                              <input className=" " id="android-app-couple-collection" name="android-app-couple-collection" count="59" priority="1.7976931348623157E308" filtered_count="59" hotel_count="59" label="OYOs welcomes couples" data-state="OYOs welcomes couples" excludequery="true" extraprops="[object Object]" type="checkbox">
                              <span lazyload="true" className="checkbox__checkmark checkbox__checkmark--  c-1m1i8cc">
                                 
                                 <svg viewBox="0 0 12 9" version="1.1" id="checkmark" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g id="Arash" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                       <g id="Listing_ListView" transform="translate(-22.000000, -260.000000)" fill="white">
                                          <g id="component/filters" transform="translate(0.000000, 96.000000)">
                                             <g id="ic/checkbox/inactive-copy-4" transform="translate(20.000000, 160.000000)">
                                                <polygon id="Fill-3-Copy-9" points="2 8.68138196 3.20857143 7.46353167 6.28571429 10.5556622 12.7914286 4 14 5.22648752 6.28571429 13"></polygon>
                                             </g>
                                          </g>
                                       </g>
                                    </g>
                                 </svg>
                              </span>
                              <div className="checkbox__label">OYOs welcomes couples</div>
                           </label>
                        </div>
                     </div>
                  </div>
                  <div className="filters__wrapper">
                     <div className="filters__item">
                        <div className="checkBoxGroup">
                           <h4>Categories</h4>
                           <label className="checkbox  " htmlfor="oyorooms">
                              <input className=" " id="oyorooms" name="oyorooms" description="Super affordable stays with essential amenities" priority="1.7976931348623157E308" label="[object Object]" data-state="[object Object]" excludequery="true" extraprops="[object Object]" type="checkbox">
                              <span lazyload="true" className="checkbox__checkmark checkbox__checkmark--  c-1m1i8cc">
                                 
                                 <svg viewBox="0 0 12 9" version="1.1" id="checkmark" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g id="Arash" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                       <g id="Listing_ListView" transform="translate(-22.000000, -260.000000)" fill="white">
                                          <g id="component/filters" transform="translate(0.000000, 96.000000)">
                                             <g id="ic/checkbox/inactive-copy-4" transform="translate(20.000000, 160.000000)">
                                                <polygon id="Fill-3-Copy-9" points="2 8.68138196 3.20857143 7.46353167 6.28571429 10.5556622 12.7914286 4 14 5.22648752 6.28571429 13"></polygon>
                                             </g>
                                          </g>
                                       </g>
                                    </g>
                                 </svg>
                              </span>
                              <div className="checkbox__label"><span><span className="is-fontBold">OYO Rooms</span> - Super affordable stays with essential amenities</span></div>
                           </label>
                           <label className="checkbox  " htmlfor="townhouse">
                              <input className=" " id="townhouse" name="townhouse" description="Your friendly, premium neighbourhood hotel- Serviced by OYO" priority="1.7976931348623157E308" label="[object Object]" data-state="[object Object]" excludequery="true" extraprops="[object Object]" type="checkbox">
                              <span lazyload="true" className="checkbox__checkmark checkbox__checkmark--  c-1m1i8cc">
                                 
                                 <svg viewBox="0 0 12 9" version="1.1" id="checkmark" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g id="Arash" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                       <g id="Listing_ListView" transform="translate(-22.000000, -260.000000)" fill="white">
                                          <g id="component/filters" transform="translate(0.000000, 96.000000)">
                                             <g id="ic/checkbox/inactive-copy-4" transform="translate(20.000000, 160.000000)">
                                                <polygon id="Fill-3-Copy-9" points="2 8.68138196 3.20857143 7.46353167 6.28571429 10.5556622 12.7914286 4 14 5.22648752 6.28571429 13"></polygon>
                                             </g>
                                          </g>
                                       </g>
                                    </g>
                                 </svg>
                              </span>
                              <div className="checkbox__label"><span><span className="is-fontBold">Townhouse</span> - Your friendly, premium neighbourhood hotel- Serviced by OYO</span></div>
                           </label>
                           <label className="checkbox  " htmlfor="flagship">
                              <input className=" " id="flagship" name="flagship" description="Affordable hotels at Prime locations- Serviced by OYO" priority="1.7976931348623157E308" label="[object Object]" data-state="[object Object]" excludequery="true" extraprops="[object Object]" type="checkbox">
                              <span lazyload="true" className="checkbox__checkmark checkbox__checkmark--  c-1m1i8cc">
                                 
                                 <svg viewBox="0 0 12 9" version="1.1" id="checkmark" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g id="Arash" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                       <g id="Listing_ListView" transform="translate(-22.000000, -260.000000)" fill="white">
                                          <g id="component/filters" transform="translate(0.000000, 96.000000)">
                                             <g id="ic/checkbox/inactive-copy-4" transform="translate(20.000000, 160.000000)">
                                                <polygon id="Fill-3-Copy-9" points="2 8.68138196 3.20857143 7.46353167 6.28571429 10.5556622 12.7914286 4 14 5.22648752 6.28571429 13"></polygon>
                                             </g>
                                          </g>
                                       </g>
                                    </g>
                                 </svg>
                              </span>
                              <div className="checkbox__label"><span><span className="is-fontBold">Flagship</span> - Affordable hotels at Prime locations- Serviced by OYO</span></div>
                           </label>
                           <label className="checkbox  " htmlfor="capitalo">
                              <input className=" " id="capitalo" name="capitalo" description="Premium hotels with spacious rooms for business travellers &amp; families" priority="1.7976931348623157E308" label="[object Object]" data-state="[object Object]" excludequery="true" extraprops="[object Object]" type="checkbox">
                              <span lazyload="true" className="checkbox__checkmark checkbox__checkmark--  c-1m1i8cc">
                                 
                                 <svg viewBox="0 0 12 9" version="1.1" id="checkmark" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g id="Arash" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                       <g id="Listing_ListView" transform="translate(-22.000000, -260.000000)" fill="white">
                                          <g id="component/filters" transform="translate(0.000000, 96.000000)">
                                             <g id="ic/checkbox/inactive-copy-4" transform="translate(20.000000, 160.000000)">
                                                <polygon id="Fill-3-Copy-9" points="2 8.68138196 3.20857143 7.46353167 6.28571429 10.5556622 12.7914286 4 14 5.22648752 6.28571429 13"></polygon>
                                             </g>
                                          </g>
                                       </g>
                                    </g>
                                 </svg>
                              </span>
                              <div className="checkbox__label"><span><span className="is-fontBold">Capital O</span> - Premium hotels with spacious rooms for business travellers &amp; families</span></div>
                           </label>
                           <label className="checkbox  " htmlfor="collectiono">
                              <input className=" " id="collectiono" name="collectiono" description="A space for new-age travellers - Serviced by OYO" priority="1.7976931348623157E308" label="[object Object]" data-state="[object Object]" excludequery="true" extraprops="[object Object]" type="checkbox">
                              <span lazyload="true" className="checkbox__checkmark checkbox__checkmark--  c-1m1i8cc">
                                 
                                 <svg viewBox="0 0 12 9" version="1.1" id="checkmark" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g id="Arash" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                       <g id="Listing_ListView" transform="translate(-22.000000, -260.000000)" fill="white">
                                          <g id="component/filters" transform="translate(0.000000, 96.000000)">
                                             <g id="ic/checkbox/inactive-copy-4" transform="translate(20.000000, 160.000000)">
                                                <polygon id="Fill-3-Copy-9" points="2 8.68138196 3.20857143 7.46353167 6.28571429 10.5556622 12.7914286 4 14 5.22648752 6.28571429 13"></polygon>
                                             </g>
                                          </g>
                                       </g>
                                    </g>
                                 </svg>
                              </span>
                              <div className="checkbox__label"><span><span className="is-fontBold">Collection O</span> - A space for new-age travellers - Serviced by OYO</span></div>
                           </label>
                           <label className="checkbox c-1hyfx7x" htmlfor="spoton">
                              <input className="c-1hyfx7x" id="spoton" name="spoton" description="Budget stay with Comfortable bed and Clean washroom" priority="1.7976931348623157E308" label="[object Object]" data-state="[object Object]" excludequery="true" extraprops="[object Object]" type="checkbox">
                              <span lazyload="true" className="checkbox__checkmark checkbox__checkmark--  c-1m1i8cc">
                                 
                                 <svg viewBox="0 0 12 9" version="1.1" id="checkmark" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g id="Arash" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                       <g id="Listing_ListView" transform="translate(-22.000000, -260.000000)" fill="white">
                                          <g id="component/filters" transform="translate(0.000000, 96.000000)">
                                             <g id="ic/checkbox/inactive-copy-4" transform="translate(20.000000, 160.000000)">
                                                <polygon id="Fill-3-Copy-9" points="2 8.68138196 3.20857143 7.46353167 6.28571429 10.5556622 12.7914286 4 14 5.22648752 6.28571429 13"></polygon>
                                             </g>
                                          </g>
                                       </g>
                                    </g>
                                 </svg>
                              </span>
                              <div className="checkbox__label"><span><span className="is-fontBold">Spot On</span> - Budget stay with Comfortable bed and Clean washroom</span></div>
                           </label>
                           <label className="checkbox c-1hyfx7x" htmlfor="oyohotels">
                              <input className="c-1hyfx7x" id="oyohotels" name="oyohotels" description="Super affordable stays with essential amenities" priority="1.7976931348623157E308" label="[object Object]" data-state="[object Object]" excludequery="true" extraprops="[object Object]" type="checkbox">
                              <span lazyload="true" className="checkbox__checkmark checkbox__checkmark--  c-1m1i8cc">
                                 
                                 <svg viewBox="0 0 12 9" version="1.1" id="checkmark" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g id="Arash" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                       <g id="Listing_ListView" transform="translate(-22.000000, -260.000000)" fill="white">
                                          <g id="component/filters" transform="translate(0.000000, 96.000000)">
                                             <g id="ic/checkbox/inactive-copy-4" transform="translate(20.000000, 160.000000)">
                                                <polygon id="Fill-3-Copy-9" points="2 8.68138196 3.20857143 7.46353167 6.28571429 10.5556622 12.7914286 4 14 5.22648752 6.28571429 13"></polygon>
                                             </g>
                                          </g>
                                       </g>
                                    </g>
                                 </svg>
                              </span>
                              <div className="checkbox__label"><span><span className="is-fontBold">OYO Hotels</span> - Super affordable stays with essential amenities</span></div>
                           </label>
                           <label className="checkbox c-1hyfx7x" htmlfor="townhouseoak">
                              <input className="c-1hyfx7x" id="townhouseoak" name="townhouseoak" description="A touch of luxury for Leisure and Business Traveller" priority="1.7976931348623157E308" label="[object Object]" data-state="[object Object]" excludequery="true" extraprops="[object Object]" type="checkbox">
                              <span lazyload="true" className="checkbox__checkmark checkbox__checkmark--  c-1m1i8cc">
                                 
                                 <svg viewBox="0 0 12 9" version="1.1" id="checkmark" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g id="Arash" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                       <g id="Listing_ListView" transform="translate(-22.000000, -260.000000)" fill="white">
                                          <g id="component/filters" transform="translate(0.000000, 96.000000)">
                                             <g id="ic/checkbox/inactive-copy-4" transform="translate(20.000000, 160.000000)">
                                                <polygon id="Fill-3-Copy-9" points="2 8.68138196 3.20857143 7.46353167 6.28571429 10.5556622 12.7914286 4 14 5.22648752 6.28571429 13"></polygon>
                                             </g>
                                          </g>
                                       </g>
                                    </g>
                                 </svg>
                              </span>
                              <div className="checkbox__label"><span><span className="is-fontBold">Townhouse Oak</span> - A touch of luxury for Leisure and Business Traveller</span></div>
                           </label>
                           <span tabindex="0" role="button" className="checkBoxGroup__viewMore">+ View More</span>
                        </div>
                     </div>
                  </div>
                  <div className="filters__wrapper">
                     <div className="filters__item">
                        <div className="checkBoxGroup">
                           <h4>Accomodation Type</h4>
                           <label className="checkbox  " htmlfor="10">
                              <input className=" " id="10" name="10" label="OYO Home" data-state="OYO Home" excludequery="true" extraprops="[object Object]" type="checkbox">
                              <span lazyload="true" className="checkbox__checkmark checkbox__checkmark--  c-1m1i8cc">
                                 
                                 <svg viewBox="0 0 12 9" version="1.1" id="checkmark" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g id="Arash" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                       <g id="Listing_ListView" transform="translate(-22.000000, -260.000000)" fill="white">
                                          <g id="component/filters" transform="translate(0.000000, 96.000000)">
                                             <g id="ic/checkbox/inactive-copy-4" transform="translate(20.000000, 160.000000)">
                                                <polygon id="Fill-3-Copy-9" points="2 8.68138196 3.20857143 7.46353167 6.28571429 10.5556622 12.7914286 4 14 5.22648752 6.28571429 13"></polygon>
                                             </g>
                                          </g>
                                       </g>
                                    </g>
                                 </svg>
                              </span>
                              <div className="checkbox__label">OYO Home</div>
                           </label>
                           <label className="checkbox  " htmlfor="0">
                              <input className=" " id="0" name="0" label="Hotel" data-state="Hotel" excludequery="true" extraprops="[object Object]" type="checkbox">
                              <span lazyload="true" className="checkbox__checkmark checkbox__checkmark--  c-1m1i8cc">
                                 
                                 <svg viewBox="0 0 12 9" version="1.1" id="checkmark" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g id="Arash" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                       <g id="Listing_ListView" transform="translate(-22.000000, -260.000000)" fill="white">
                                          <g id="component/filters" transform="translate(0.000000, 96.000000)">
                                             <g id="ic/checkbox/inactive-copy-4" transform="translate(20.000000, 160.000000)">
                                                <polygon id="Fill-3-Copy-9" points="2 8.68138196 3.20857143 7.46353167 6.28571429 10.5556622 12.7914286 4 14 5.22648752 6.28571429 13"></polygon>
                                             </g>
                                          </g>
                                       </g>
                                    </g>
                                 </svg>
                              </span>
                              <div className="checkbox__label">Hotel</div>
                           </label>
                        </div>
                     </div>
                  </div>
                  <div className="filters__wrapper">
                     <div className="filters__item">
                        <div className="checkBoxGroup">
                           <h4>Hotel Facilities</h4>
                           <label className="checkbox  " htmlfor="11">
                              <input className=" " id="11" name="11" priority="1.7976931348623157E308" icon_id="143" label="Seating area" data-state="Seating area" excludequery="true" extraprops="[object Object]" type="checkbox">
                              <span lazyload="true" className="checkbox__checkmark checkbox__checkmark--  c-1m1i8cc">
                                 
                                 <svg viewBox="0 0 12 9" version="1.1" id="checkmark" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g id="Arash" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                       <g id="Listing_ListView" transform="translate(-22.000000, -260.000000)" fill="white">
                                          <g id="component/filters" transform="translate(0.000000, 96.000000)">
                                             <g id="ic/checkbox/inactive-copy-4" transform="translate(20.000000, 160.000000)">
                                                <polygon id="Fill-3-Copy-9" points="2 8.68138196 3.20857143 7.46353167 6.28571429 10.5556622 12.7914286 4 14 5.22648752 6.28571429 13"></polygon>
                                             </g>
                                          </g>
                                       </g>
                                    </g>
                                 </svg>
                              </span>
                              <div className="checkbox__label">Seating area</div>
                           </label>
                           <label className="checkbox  " htmlfor="13">
                              <input className=" " id="13" name="13" priority="1.7976931348623157E308" icon_id="13" label="AC" data-state="AC" excludequery="true" extraprops="[object Object]" type="checkbox">
                              <span lazyload="true" className="checkbox__checkmark checkbox__checkmark--  c-1m1i8cc">
                                 
                                 <svg viewBox="0 0 12 9" version="1.1" id="checkmark" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g id="Arash" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                       <g id="Listing_ListView" transform="translate(-22.000000, -260.000000)" fill="white">
                                          <g id="component/filters" transform="translate(0.000000, 96.000000)">
                                             <g id="ic/checkbox/inactive-copy-4" transform="translate(20.000000, 160.000000)">
                                                <polygon id="Fill-3-Copy-9" points="2 8.68138196 3.20857143 7.46353167 6.28571429 10.5556622 12.7914286 4 14 5.22648752 6.28571429 13"></polygon>
                                             </g>
                                          </g>
                                       </g>
                                    </g>
                                 </svg>
                              </span>
                              <div className="checkbox__label">AC</div>
                           </label>
                           <label className="checkbox  " htmlfor="345">
                              <input className=" " id="345" name="345" priority="1.7976931348623157E308" icon_id="489" label="Full Sized Bed" data-state="Full Sized Bed" excludequery="true" extraprops="[object Object]" type="checkbox">
                              <span lazyload="true" className="checkbox__checkmark checkbox__checkmark--  c-1m1i8cc">
                                 
                                 <svg viewBox="0 0 12 9" version="1.1" id="checkmark" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g id="Arash" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                       <g id="Listing_ListView" transform="translate(-22.000000, -260.000000)" fill="white">
                                          <g id="component/filters" transform="translate(0.000000, 96.000000)">
                                             <g id="ic/checkbox/inactive-copy-4" transform="translate(20.000000, 160.000000)">
                                                <polygon id="Fill-3-Copy-9" points="2 8.68138196 3.20857143 7.46353167 6.28571429 10.5556622 12.7914286 4 14 5.22648752 6.28571429 13"></polygon>
                                             </g>
                                          </g>
                                       </g>
                                    </g>
                                 </svg>
                              </span>
                              <div className="checkbox__label">Full Sized Bed</div>
                           </label>
                           <label className="checkbox  " htmlfor="154">
                              <input className=" " id="154" name="154" priority="1.7976931348623157E308" icon_id="147" label="King Sized Bed" data-state="King Sized Bed" excludequery="true" extraprops="[object Object]" type="checkbox">
                              <span lazyload="true" className="checkbox__checkmark checkbox__checkmark--  c-1m1i8cc">
                                 
                                 <svg viewBox="0 0 12 9" version="1.1" id="checkmark" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g id="Arash" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                       <g id="Listing_ListView" transform="translate(-22.000000, -260.000000)" fill="white">
                                          <g id="component/filters" transform="translate(0.000000, 96.000000)">
                                             <g id="ic/checkbox/inactive-copy-4" transform="translate(20.000000, 160.000000)">
                                                <polygon id="Fill-3-Copy-9" points="2 8.68138196 3.20857143 7.46353167 6.28571429 10.5556622 12.7914286 4 14 5.22648752 6.28571429 13"></polygon>
                                             </g>
                                          </g>
                                       </g>
                                    </g>
                                 </svg>
                              </span>
                              <div className="checkbox__label">King Sized Bed</div>
                           </label>
                           <label className="checkbox  " htmlfor="38">
                              <input className=" " id="38" name="38" priority="1.7976931348623157E308" icon_id="79" label="Mini Fridge" data-state="Mini Fridge" excludequery="true" extraprops="[object Object]" type="checkbox">
                              <span lazyload="true" className="checkbox__checkmark checkbox__checkmark--  c-1m1i8cc">
                                 
                                 <svg viewBox="0 0 12 9" version="1.1" id="checkmark" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g id="Arash" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                       <g id="Listing_ListView" transform="translate(-22.000000, -260.000000)" fill="white">
                                          <g id="component/filters" transform="translate(0.000000, 96.000000)">
                                             <g id="ic/checkbox/inactive-copy-4" transform="translate(20.000000, 160.000000)">
                                                <polygon id="Fill-3-Copy-9" points="2 8.68138196 3.20857143 7.46353167 6.28571429 10.5556622 12.7914286 4 14 5.22648752 6.28571429 13"></polygon>
                                             </g>
                                          </g>
                                       </g>
                                    </g>
                                 </svg>
                              </span>
                              <div className="checkbox__label">Mini Fridge</div>
                           </label>
                           <label className="checkbox c-1hyfx7x" htmlfor="39">
                              <input className="c-1hyfx7x" id="39" name="39" priority="1.7976931348623157E308" icon_id="76" label="TV" data-state="TV" excludequery="true" extraprops="[object Object]" type="checkbox">
                              <span lazyload="true" className="checkbox__checkmark checkbox__checkmark--  c-1m1i8cc">
                                 
                                 <svg viewBox="0 0 12 9" version="1.1" id="checkmark" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g id="Arash" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                       <g id="Listing_ListView" transform="translate(-22.000000, -260.000000)" fill="white">
                                          <g id="component/filters" transform="translate(0.000000, 96.000000)">
                                             <g id="ic/checkbox/inactive-copy-4" transform="translate(20.000000, 160.000000)">
                                                <polygon id="Fill-3-Copy-9" points="2 8.68138196 3.20857143 7.46353167 6.28571429 10.5556622 12.7914286 4 14 5.22648752 6.28571429 13"></polygon>
                                             </g>
                                          </g>
                                       </g>
                                    </g>
                                 </svg>
                              </span>
                              <div className="checkbox__label">TV</div>
                           </label>
                           <label className="checkbox c-1hyfx7x" htmlfor="40">
                              <input className="c-1hyfx7x" id="40" name="40" priority="1.7976931348623157E308" icon_id="83" label="Hair Dryer" data-state="Hair Dryer" excludequery="true" extraprops="[object Object]" type="checkbox">
                              <span lazyload="true" className="checkbox__checkmark checkbox__checkmark--  c-1m1i8cc">
                                 
                                 <svg viewBox="0 0 12 9" version="1.1" id="checkmark" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g id="Arash" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                       <g id="Listing_ListView" transform="translate(-22.000000, -260.000000)" fill="white">
                                          <g id="component/filters" transform="translate(0.000000, 96.000000)">
                                             <g id="ic/checkbox/inactive-copy-4" transform="translate(20.000000, 160.000000)">
                                                <polygon id="Fill-3-Copy-9" points="2 8.68138196 3.20857143 7.46353167 6.28571429 10.5556622 12.7914286 4 14 5.22648752 6.28571429 13"></polygon>
                                             </g>
                                          </g>
                                       </g>
                                    </g>
                                 </svg>
                              </span>
                              <div className="checkbox__label">Hair Dryer</div>
                           </label>
                           <label className="checkbox c-1hyfx7x" htmlfor="183">
                              <input className="c-1hyfx7x" id="183" name="183" priority="1.7976931348623157E308" icon_id="170" label="Attached Washroom" data-state="Attached Washroom" excludequery="true" extraprops="[object Object]" type="checkbox">
                              <span lazyload="true" className="checkbox__checkmark checkbox__checkmark--  c-1m1i8cc">
                                 
                                 <svg viewBox="0 0 12 9" version="1.1" id="checkmark" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g id="Arash" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                       <g id="Listing_ListView" transform="translate(-22.000000, -260.000000)" fill="white">
                                          <g id="component/filters" transform="translate(0.000000, 96.000000)">
                                             <g id="ic/checkbox/inactive-copy-4" transform="translate(20.000000, 160.000000)">
                                                <polygon id="Fill-3-Copy-9" points="2 8.68138196 3.20857143 7.46353167 6.28571429 10.5556622 12.7914286 4 14 5.22648752 6.28571429 13"></polygon>
                                             </g>
                                          </g>
                                       </g>
                                    </g>
                                 </svg>
                              </span>
                              <div className="checkbox__label">Attached Washroom</div>
                           </label>
                           <span tabindex="0" role="button" className="checkBoxGroup__viewMore">+ View More</span>
                        </div>
                     </div>
                  </div>
                  <div className="filters__wrapper">
                     <div className="filters__item">
                        <div className="w-filter">
                           <div className="w-filter-contentCont">
                              <span lazyload="true" className="c-1in37i9 w-icon-default w-filter-icon">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="55" height="46" viewBox="0 0 55 46">
                                    <g fill="none" fill-rule="evenodd">
                                       <path d="M27.9 16.2c-.6-.2-1.1 0-1.4.6l-9.6 24.8L2.6 6.5c-.3-.6-.7-1-1.2-.9-.3 0-.6.2-.8.5-.2.3-.2.7-.1 1 .2.8.6 1.5.9 2.2.2.4.4.8.5 1.1l7.2 17.7c2.3 5.5 4.5 11.1 6.8 16.6.1.2.2.3.3.5l.1.1.2.2h1.1l.2-.3c0-.1.1-.1.1-.2.1-.1.2-.3.3-.5 2.9-7.5 5.8-15.1 8.7-22.6l.9-2.3c.3-.7.6-1.4.8-2.1.2-.5-.1-1.1-.7-1.3zm26.92-9.525c-.1-.3-.3-.5-.6-.6-.6-.2-1.1 0-1.4.6l-15.2 35.3-7.4-19.9v-.1c-.1-.2-.1-.3-.2-.5-.2-.5-.7-.7-1.3-.5-.5.2-.8.6-.7 1.2 0 .2.1.3.1.5l.4 1.2c2.6 7.1 5.3 14.2 7.9 21.3.1.2.2.4.3.5l.1.1.2.2h1l.2-.2.1-.1c.1-.1.2-.3.3-.5 4.6-10.7 9.2-21.5 13.9-32.2l1.5-3.4c.3-.6.5-1.3.8-1.9.1-.4.1-.7 0-1z"></path>
                                       <path d="M17.3 30.2c.3-.1.6-.4.7-.8 2.6-6.7 5.2-13.5 7.8-20.2l1.7-4.4c0-.1.1-.1.1-.2l9.2 24.8c.1.3.3.9 1.1.9.7 0 1-.6 1.1-.9L49.7 4c.2-.4.2-.8.1-1.1-.1-.2-.2-.4-.6-.6-.6-.2-1.2.1-1.4.7L38 26.1 28.7 1.2c-.3-.9-.9-.9-1.3-.8-.6.1-.8.6-.9.9l-7.9 20.2-2 5.2c-.3.6-.5 1.3-.7 1.9-.1.4 0 .8.1 1.1.3.6 1 .7 1.3.5zm-4.8-9.1c.1.3.2.6.4.9.2.4.5.6 1 .6h.2c.3-.1.8-.3.9-1v-.1l-.1-.2c0-.1-.1-.2-.1-.3L9.4 7.5c-.6-1.4-1.1-2.8-1.7-4.3-.3-.8-1-.8-1.2-.8-.3.1-.6.2-.7.5-.2.3-.2.7 0 1.1l6.7 17.1z"></path>
                                       <path d="M12.5 21.1c.1.3.2.6.4.9.2.4.5.6 1 .6h.2c.3-.1.8-.3.9-1v-.1l-.1-.2c0-.1-.1-.2-.1-.3L9.4 7.5c-.6-1.4-1.1-2.8-1.7-4.3-.3-.8-1-.8-1.2-.8-.3.1-.6.2-.7.5-.2.3-.2.7 0 1.1l6.7 17.1zM28.7 1.4c-.3-.9-.9-.9-1.3-.8-.6.1-.8.6-.9.9l-7.9 20.2-2 5.2c-.3.6-.5 1.3-.7 1.9-.1.4 0 .8.1 1.1.2.4.8.5 1.2.4.3-.1.6-.4.7-.8 2.6-6.7 5.2-13.5 7.8-20.2l1.7-4.4c0-.1.1-.1.1-.2l7 19 2-.9-7.8-21.4zm21 2.7c.2-.4.2-.8.1-1.1-.1-.2-.2-.4-.6-.6-.6-.2-1.2.1-1.4.7L40.2 21l2.9-1.3 6.6-15.6z"></path>
                                       <path d="M30.22 22.177v-.1c-.1-.2-.1-.3-.2-.5-.2-.5-.7-.7-1.3-.5-.5.2-.8.6-.7 1.2 0 .2.1.3.1.5l.4 1.2c.3.9.6 1.7 1 2.6l2-.9-1.3-3.5zM51 16.1c.5-1.2 1.1-2.5 1.6-3.7L54.1 9c.3-.6.5-1.3.8-1.9.1-.3.1-.6 0-.9-.1-.3-.3-.5-.6-.6-.6-.2-1.1 0-1.4.6l-4.8 11.2 2.9-1.3zM2.6 6.5c-.3-.6-.7-1-1.2-.9-.3 0-.6.2-.8.5-.2.3-.2.7-.1 1 .2.8.6 1.5.9 2.2.2.4.4.8.5 1.1l7.2 17.7c.8 2 1.7 4.1 2.5 6.1l2-.9-11-26.8zM26.8 22l.9-2.3c.3-.7.6-1.4.8-2.1.2-.6 0-1.2-.6-1.4-.6-.2-1.1 0-1.4.6l-5 12.8 2.8-1.3c.9-2 1.7-4.1 2.5-6.3z"></path>
                                    </g>
                                 </svg>
                              </span>
                              <div className="w-filter-content"><span className="w-filter-name">Wizard Member OYOs</span><span className="w-filter-desc">Get 5% off on member hotels</span></div>
                           </div>
                           <button className="c-1k6asfw w-filter-btn" type="button">
                              <span lazyload="true" className="c-1in37i9 w-icon-default w-filter-btn-icon">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="55" height="46" viewBox="0 0 55 46">
                                    <g fill="none" fill-rule="evenodd">
                                       <path d="M27.9 16.2c-.6-.2-1.1 0-1.4.6l-9.6 24.8L2.6 6.5c-.3-.6-.7-1-1.2-.9-.3 0-.6.2-.8.5-.2.3-.2.7-.1 1 .2.8.6 1.5.9 2.2.2.4.4.8.5 1.1l7.2 17.7c2.3 5.5 4.5 11.1 6.8 16.6.1.2.2.3.3.5l.1.1.2.2h1.1l.2-.3c0-.1.1-.1.1-.2.1-.1.2-.3.3-.5 2.9-7.5 5.8-15.1 8.7-22.6l.9-2.3c.3-.7.6-1.4.8-2.1.2-.5-.1-1.1-.7-1.3zm26.92-9.525c-.1-.3-.3-.5-.6-.6-.6-.2-1.1 0-1.4.6l-15.2 35.3-7.4-19.9v-.1c-.1-.2-.1-.3-.2-.5-.2-.5-.7-.7-1.3-.5-.5.2-.8.6-.7 1.2 0 .2.1.3.1.5l.4 1.2c2.6 7.1 5.3 14.2 7.9 21.3.1.2.2.4.3.5l.1.1.2.2h1l.2-.2.1-.1c.1-.1.2-.3.3-.5 4.6-10.7 9.2-21.5 13.9-32.2l1.5-3.4c.3-.6.5-1.3.8-1.9.1-.4.1-.7 0-1z"></path>
                                       <path d="M17.3 30.2c.3-.1.6-.4.7-.8 2.6-6.7 5.2-13.5 7.8-20.2l1.7-4.4c0-.1.1-.1.1-.2l9.2 24.8c.1.3.3.9 1.1.9.7 0 1-.6 1.1-.9L49.7 4c.2-.4.2-.8.1-1.1-.1-.2-.2-.4-.6-.6-.6-.2-1.2.1-1.4.7L38 26.1 28.7 1.2c-.3-.9-.9-.9-1.3-.8-.6.1-.8.6-.9.9l-7.9 20.2-2 5.2c-.3.6-.5 1.3-.7 1.9-.1.4 0 .8.1 1.1.3.6 1 .7 1.3.5zm-4.8-9.1c.1.3.2.6.4.9.2.4.5.6 1 .6h.2c.3-.1.8-.3.9-1v-.1l-.1-.2c0-.1-.1-.2-.1-.3L9.4 7.5c-.6-1.4-1.1-2.8-1.7-4.3-.3-.8-1-.8-1.2-.8-.3.1-.6.2-.7.5-.2.3-.2.7 0 1.1l6.7 17.1z"></path>
                                       <path d="M12.5 21.1c.1.3.2.6.4.9.2.4.5.6 1 .6h.2c.3-.1.8-.3.9-1v-.1l-.1-.2c0-.1-.1-.2-.1-.3L9.4 7.5c-.6-1.4-1.1-2.8-1.7-4.3-.3-.8-1-.8-1.2-.8-.3.1-.6.2-.7.5-.2.3-.2.7 0 1.1l6.7 17.1zM28.7 1.4c-.3-.9-.9-.9-1.3-.8-.6.1-.8.6-.9.9l-7.9 20.2-2 5.2c-.3.6-.5 1.3-.7 1.9-.1.4 0 .8.1 1.1.2.4.8.5 1.2.4.3-.1.6-.4.7-.8 2.6-6.7 5.2-13.5 7.8-20.2l1.7-4.4c0-.1.1-.1.1-.2l7 19 2-.9-7.8-21.4zm21 2.7c.2-.4.2-.8.1-1.1-.1-.2-.2-.4-.6-.6-.6-.2-1.2.1-1.4.7L40.2 21l2.9-1.3 6.6-15.6z"></path>
                                       <path d="M30.22 22.177v-.1c-.1-.2-.1-.3-.2-.5-.2-.5-.7-.7-1.3-.5-.5.2-.8.6-.7 1.2 0 .2.1.3.1.5l.4 1.2c.3.9.6 1.7 1 2.6l2-.9-1.3-3.5zM51 16.1c.5-1.2 1.1-2.5 1.6-3.7L54.1 9c.3-.6.5-1.3.8-1.9.1-.3.1-.6 0-.9-.1-.3-.3-.5-.6-.6-.6-.2-1.1 0-1.4.6l-4.8 11.2 2.9-1.3zM2.6 6.5c-.3-.6-.7-1-1.2-.9-.3 0-.6.2-.8.5-.2.3-.2.7-.1 1 .2.8.6 1.5.9 2.2.2.4.4.8.5 1.1l7.2 17.7c.8 2 1.7 4.1 2.5 6.1l2-.9-11-26.8zM26.8 22l.9-2.3c.3-.7.6-1.4.8-2.1.2-.6 0-1.2-.6-1.4-.6-.2-1.1 0-1.4.6l-5 12.8 2.8-1.3c.9-2 1.7-4.1 2.5-6.3z"></path>
                                    </g>
                                 </svg>
                              </span>
                              <span className="w-filter-btn-text">Show Only Wizard Member OYOs</span>
                           </button>
                        </div>
                     </div>
                  </div>
                  <div className="filters__wrapper">
                     <div className="filters__item">
                        <div className="checkBoxGroup">
                           <h4>Check-in features</h4>
                           <label className="checkbox  " htmlfor="postpaid_allowed">
                              <input className=" " id="postpaid_allowed" name="postpaid_allowed" label="Pay at Hotel" data-state="Pay at Hotel" excludequery="true" extraprops="[object Object]" type="checkbox">
                              <span lazyload="true" className="checkbox__checkmark checkbox__checkmark--  c-1m1i8cc">
                                 
                                 <svg viewBox="0 0 12 9" version="1.1" id="checkmark" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g id="Arash" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                       <g id="Listing_ListView" transform="translate(-22.000000, -260.000000)" fill="white">
                                          <g id="component/filters" transform="translate(0.000000, 96.000000)">
                                             <g id="ic/checkbox/inactive-copy-4" transform="translate(20.000000, 160.000000)">
                                                <polygon id="Fill-3-Copy-9" points="2 8.68138196 3.20857143 7.46353167 6.28571429 10.5556622 12.7914286 4 14 5.22648752 6.28571429 13"></polygon>
                                             </g>
                                          </g>
                                       </g>
                                    </g>
                                 </svg>
                              </span>
                              <div className="checkbox__label">Pay at Hotel</div>
                           </label>
                        </div>
                     </div>
                  </div>
               </div>
      </aside>
      */}
             <section className="listing__content">
                <div className="listing__contentWrapper">
                   <div className="ListingContentHeader">
                      <div className="ListingContentHeader__heading">
                         <span>
                            <h1 className="ListingContentHeader__h1">Library in Patna,Bihar,India</h1>
                         </span>
                         <div className="ListingContentHeader__mapToggle">
                            <span className="ListingContentHeader__mapToggle--label">Map View</span>
                            <label className="ToggleButton">
                               <input type="checkbox" className="ToggleButton__input" name="test" />
                               <span className="ToggleButton__sliderRound"></span>
                            </label>
                         </div>
                         <div>
                            <div className="dropdown">
                               <span className="dropdown__label">Sort By</span>
                               <span tabindex="-1" className="dropdown__select">
                                  Popularity
                                  <span lazyload="true" className="dropdown__icon">
                                     <svg width="8" height="6" viewBox="0 0 8 6" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 0h8L4 6z" fill="black" fill-rule="evenodd" opacity=".87"></path>
                                     </svg>
                                  </span>
                                  <ul className="dropdown__list">
                                     <li className="dropdown__item"><span>Popularity &nbsp;</span></li>
                                     <li className="dropdown__item"><span>Guest Ratings &nbsp;</span></li>
                                     <li className="dropdown__item"><span>Price Low to High &nbsp;</span></li>
                                     <li className="dropdown__item"><span>Price High to Low &nbsp;</span></li>
                                  </ul>
                               </span>
                            </div>
                         </div>
                      </div>
                      <div className="ListingContentHeader__divider"></div>
                      <div className="listingBanners">
                         <div className="listingBanners__section">
                            <div className="listingBanners__card listingBanners__loginWrapper">
                               <div className="listingBanners__loginWrapper--icon">
                                  <span lazyload="true" className="c-1in37i9 u-RTLNegative">
                                     <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.5003 4.16651C7.04037 4.16651 6.6665 3.79387 6.6665 3.33272V2.50015C6.6665 1.12128 7.78779 0 9.16666 0H18.1259C18.5859 0 18.9597 0.372642 18.9597 0.833791C18.9597 1.29494 18.5859 1.66636 18.1259 1.66636H9.16666C8.70673 1.66636 8.33287 2.04022 8.33287 2.50015V3.33394C8.33409 3.79387 7.96022 4.16651 7.5003 4.16651Z" fill="#00B28A" fill-opacity="0.5"></path>
                                        <path d="M12.0846 17.501H9.16812C7.78926 17.501 6.66797 16.3798 6.66797 15.0009V13.3345C6.66675 12.8734 7.04061 12.5007 7.50054 12.5007C7.96047 12.5007 8.33433 12.8734 8.33433 13.3345V15.0009C8.33433 15.4608 8.7082 15.8347 9.16812 15.8347H12.0846C12.5445 15.8347 12.9184 16.2073 12.9184 16.6685C12.9171 17.1284 12.5445 17.501 12.0846 17.501Z" fill="#00B28A" fill-opacity="0.5"></path>
                                        <path d="M5.58903 12.257C5.35036 12.4959 4.99268 12.567 4.68016 12.4382C4.36916 12.3094 4.16651 12.0058 4.16651 11.6682V9.16808H0.833791C0.372642 9.16686 0 8.79422 0 8.33429C0 7.87436 0.372642 7.5005 0.833791 7.5005H4.16774V5.00035C4.16774 4.6628 4.37038 4.35913 4.68168 4.23034C4.99268 4.10155 5.35158 4.17266 5.59025 4.41163L8.92419 7.74557C9.24922 8.0706 9.24922 8.59798 8.92419 8.92423L5.58903 12.257Z" fill="#00B28A"></path>
                                        <path d="M17.8035 0.0812621L12.7959 1.75006C12.1208 1.98506 11.667 2.62017 11.667 3.3328V18.3337C11.667 19.2523 12.4144 20.0001 13.3334 20.0001C13.5134 20.0001 13.6822 19.975 13.8647 19.9189L18.8735 18.2486C19.5474 18.0148 20.0012 17.3788 20.0012 16.6674V1.66644C20.0012 0.568963 18.9224 -0.261166 17.8035 0.0812621Z" fill="#00B28A"></path>
                                     </svg>
                                  </span>
                               </div>
                               <div className="listingBanners__loginWrapper__text">
                                  <div className="listingBanners__loginWrapper__text--heading">
                                     <span className="listingBanners__loginWrapper__text--description"> Your  </span>
                                     <span className="listingBanners__loginWrapper__text--heading--amount">₹1500</span>
                                     <span className="listingBanners__loginWrapper__text--description">&nbsp;OYO Money is pre-applied on all properties to show best room price.</span>
                                  </div>
                               </div>
                            </div>
                         </div>
                      </div>
                   </div>
                   <div className="oyo-row oyo-row--no-spacing ListingHotelCardWrapper">
                      <div className="oyo-cell--12-col oyo-cell--8-col-tablet oyo-cell--4-col-phone">
                         <div className="oyo-row oyo-row--no-spacing hotelCardListing">
                            <div className="hotelCardListing__imgCardWrapper">
                               <div className="oyo-row oyo-row--no-spacing listingImageCard">
                                  <div className="oyo-cell--10-col listingImageCard__carousel">
                                     <div className="slick-slider slick-animatedSlideshow slick-animatedSlideshow--persistArrows slick-initialized" dir="ltr">
                                        <button type="button" data-role="none" className="slick-arrow slick-prev" style={{display:"block"}}> Previous</button>
                                        <div className="slick-list">
                                           <div className="slick-track" style={{width:"21660px", opacity: "1", transform:"translate3d(-380px, 0px, 0px)"}}>
                                              <div data-index="-1" tabindex="-1" className="slick-slide slick-cloned" aria-hidden="true" style={{width: "380px"}}></div>
                                              <div data-index="0" className="slick-slide slick-active slick-current" tabindex="-1" aria-hidden="false" style={{outline: "none", width: "380px"}}>
                                                 <div>
                                                    <div className="c-ahj8kj">
                                                       <img alt="Flagship Flagship Hotel Dream Inn" loading="auto" importance="auto" src="https://images.oyoroomscdn.com/uploads/hotel_image/109667/medium/1b41af39df1789b7.jpg" placeholdertype="hotel" className="listingImageCard__carousel__imgWrapper listingImageCard__carouselImg c-1xv0w1q" contain="true" itemprop="image" tabindex="-1" style={{width: "100%", display: "inline-block"}} />
                                                    </div>
                                                 </div>
                                              </div>
                                              <div data-index="1" className="slick-slide" tabindex="-1" aria-hidden="true" style={{outline: "none", width: "380px"}}></div>
                                              <div data-index="2" className="slick-slide" tabindex="-1" aria-hidden="true" style={{outline: "none", width: "380px"}}></div>
                                              <div data-index="3" className="slick-slide" tabindex="-1" aria-hidden="true" style={{outline: "none", width: "380px"}}></div>
                                              <div data-index="4" className="slick-slide" tabindex="-1" aria-hidden="true" style={{outline: "none", width: "380px"}}></div>
                                              <div data-index="5" className="slick-slide" tabindex="-1" aria-hidden="true" style={{outline: "none", width: "380px"}}></div>
                                              <div data-index="6" className="slick-slide" tabindex="-1" aria-hidden="true" style={{outline: "none", width: "380px"}}></div>
                                              <div data-index="7" className="slick-slide" tabindex="-1" aria-hidden="true" style={{outline: "none", width: "380px"}}></div>
                                              <div data-index="8" className="slick-slide" tabindex="-1" aria-hidden="true" style={{outline: "none", width: "380px"}}></div>
                                              <div data-index="9" className="slick-slide" tabindex="-1" aria-hidden="true" style={{outline: "none", width: "380px"}}></div>
                                              <div data-index="10" className="slick-slide" tabindex="-1" aria-hidden="true" style={{outline: "none", width: "380px"}}></div>
                                              <div data-index="11" className="slick-slide" tabindex="-1" aria-hidden="true" style={{outline: "none", width: "380px"}}></div>
                                              <div data-index="12" className="slick-slide" tabindex="-1" aria-hidden="true" style={{outline: "none", width: "380px"}}></div>
                                              <div data-index="13" className="slick-slide" tabindex="-1" aria-hidden="true" style={{outline: "none", width: "380px"}}></div>
                                              <div data-index="14" className="slick-slide" tabindex="-1" aria-hidden="true" style={{outline: "none", width: "380px"}}></div>
                                              <div data-index="15" className="slick-slide" tabindex="-1" aria-hidden="true" style={{outline: "none", width: "380px"}}></div>
                                              <div data-index="16" className="slick-slide" tabindex="-1" aria-hidden="true" style={{outline: "none", width: "380px"}}></div>
                                              <div data-index="17" className="slick-slide" tabindex="-1" aria-hidden="true" style={{outline: "none", width: "380px"}}></div>
                                              <div data-index="18" className="slick-slide" tabindex="-1" aria-hidden="true" style={{outline: "none", width: "380px"}}></div>
                                              <div data-index="19" className="slick-slide" tabindex="-1" aria-hidden="true" style={{outline: "none", width: "380px"}}></div>
                                              <div data-index="20" className="slick-slide" tabindex="-1" aria-hidden="true" style={{outline: "none", width: "380px"}}></div>
                                              <div data-index="21" className="slick-slide" tabindex="-1" aria-hidden="true" style={{outline: "none", width: "380px"}}></div>
                                              <div data-index="22" className="slick-slide" tabindex="-1" aria-hidden="true" style={{outline: "none", width: "380px"}}></div>
                                              <div data-index="23" className="slick-slide" tabindex="-1" aria-hidden="true" style={{outline: "none", width: "380px"}}></div>
                                              <div data-index="24" className="slick-slide" tabindex="-1" aria-hidden="true" style={{outline: "none", width: "380px"}}></div>
                                              <div data-index="25" className="slick-slide" tabindex="-1" aria-hidden="true" style={{outline: "none", width: "380px"}}></div>
                                              <div data-index="26" className="slick-slide" tabindex="-1" aria-hidden="true" style={{outline: "none", width: "380px"}}></div>
                                              <div data-index="27" className="slick-slide" tabindex="-1" aria-hidden="true" style={{outline: "none", width: "380px"}}></div>
                                              <div data-index="28" tabindex="-1" className="slick-slide slick-cloned" aria-hidden="true" style={{width: "380px"}}>
                                                 <div>
                                                    <div className="c-ahj8kj">
                                                       <img alt="Flagship Flagship Hotel Dream Inn" loading="auto" importance="auto" src="https://images.oyoroomscdn.com/uploads/hotel_image/109667/medium/1b41af39df1789b7.jpg" placeholdertype="hotel" className="listingImageCard__carousel__imgWrapper listingImageCard__carouselImg c-1xv0w1q" contain="true" itemprop="image" tabindex="-1" style={{width: "100%", display: "inline-block"}} />
                                                    </div>
                                                 </div>
                                              </div>
                                              <div data-index="29" tabindex="-1" className="slick-slide slick-cloned" aria-hidden="true" style={{width: "380px"}}></div>
                                              <div data-index="30" tabindex="-1" className="slick-slide slick-cloned" aria-hidden="true" style={{width: "380px"}}></div>
                                              <div data-index="31" tabindex="-1" className="slick-slide slick-cloned" aria-hidden="true" style={{width: "380px"}}></div>
                                              <div data-index="32" tabindex="-1" className="slick-slide slick-cloned" aria-hidden="true" style={{width: "380px"}}></div>
                                              <div data-index="33" tabindex="-1" className="slick-slide slick-cloned" aria-hidden="true" style={{width: "380px"}}></div>
                                              <div data-index="34" tabindex="-1" className="slick-slide slick-cloned" aria-hidden="true" style={{width: "380px"}}></div>
                                              <div data-index="35" tabindex="-1" className="slick-slide slick-cloned" aria-hidden="true" style={{width: "380px"}}></div>
                                              <div data-index="36" tabindex="-1" className="slick-slide slick-cloned" aria-hidden="true" style={{width: "380px"}}></div>
                                              <div data-index="37" tabindex="-1" className="slick-slide slick-cloned" aria-hidden="true" style={{width: "380px"}}></div>
                                              <div data-index="38" tabindex="-1" className="slick-slide slick-cloned" aria-hidden="true" style={{width: "380px"}}></div>
                                              <div data-index="39" tabindex="-1" className="slick-slide slick-cloned" aria-hidden="true" style={{width: "380px"}}></div>
                                              <div data-index="40" tabindex="-1" className="slick-slide slick-cloned" aria-hidden="true" style={{width: "380px"}}></div>
                                              <div data-index="41" tabindex="-1" className="slick-slide slick-cloned" aria-hidden="true" style={{width: "380px"}}></div>
                                              <div data-index="42" tabindex="-1" className="slick-slide slick-cloned" aria-hidden="true" style={{width: "380px"}}></div>
                                              <div data-index="43" tabindex="-1" className="slick-slide slick-cloned" aria-hidden="true" style={{width: "380px"}}></div>
                                              <div data-index="44" tabindex="-1" className="slick-slide slick-cloned" aria-hidden="true" style={{width: "380px"}}></div>
                                              <div data-index="45" tabindex="-1" className="slick-slide slick-cloned" aria-hidden="true" style={{width: "380px"}}></div>
                                              <div data-index="46" tabindex="-1" className="slick-slide slick-cloned" aria-hidden="true" style={{width: "380px"}}></div>
                                              <div data-index="47" tabindex="-1" className="slick-slide slick-cloned" aria-hidden="true" style={{width: "380px"}}></div>
                                              <div data-index="48" tabindex="-1" className="slick-slide slick-cloned" aria-hidden="true" style={{width: "380px"}}></div>
                                              <div data-index="49" tabindex="-1" className="slick-slide slick-cloned" aria-hidden="true" style={{width: "380px"}}></div>
                                              <div data-index="50" tabindex="-1" className="slick-slide slick-cloned" aria-hidden="true" style={{width: "380px"}}></div>
                                              <div data-index="51" tabindex="-1" className="slick-slide slick-cloned" aria-hidden="true" style={{width: "380px"}}></div>
                                              <div data-index="52" tabindex="-1" className="slick-slide slick-cloned" aria-hidden="true" style={{width: "380px"}}></div>
                                              <div data-index="53" tabindex="-1" className="slick-slide slick-cloned" aria-hidden="true" style={{width: "380px"}}></div>
                                              <div data-index="54" tabindex="-1" className="slick-slide slick-cloned" aria-hidden="true" style={{width: "380px"}}></div>
                                              <div data-index="55" tabindex="-1" className="slick-slide slick-cloned" aria-hidden="true" style={{width: "380px"}}></div>
                                           </div>
                                        </div>
                                        <button type="button" data-role="none" className="slick-arrow slick-next" style={{display: "block"}}> Next</button>
                                     </div>
                                     <span className="c-1fnzz2s"> Flagship</span>
                                  </div>
                                  <div className="listingImageCard__sideImages">
                                     <div className="listingImageCard__img">
                                        <div className="c-ahj8kj">
                                           <img alt="Flagship Flagship Hotel Dream Inn" loading="auto" importance="auto" src="https://images.oyoroomscdn.com/uploads/hotel_image/109667/thumb/f171d7d37c1f995b.jpg" placeholdertype="hotel" className="c-2tglnv listingImageCard__img--full listingImageCard__sideImages--borderRadius" itemprop="image" />
                                        </div>
                                     </div>
                                     <div className="listingImageCard__img">
                                        <div className="c-ahj8kj">
                                           <img alt="Flagship Flagship Hotel Dream Inn" loading="auto" importance="auto" src="https://images.oyoroomscdn.com/uploads/hotel_image/109667/thumb/1254b4b2dc4b810d.jpg" placeholdertype="hotel" className="c-2tglnv listingImageCard__img--full " itemprop="image" />
                                        </div>
                                     </div>
                                     <div className="listingImageCard__img">
                                        <div className="c-ahj8kj">
                                           <img alt="Flagship Flagship Hotel Dream Inn" loading="auto" importance="auto" src="https://images.oyoroomscdn.com/uploads/hotel_image/109667/thumb/7fb6730127c36458.jpg" placeholdertype="hotel" className="c-2tglnv listingImageCard__img--full " itemprop="image" />
                                        </div>
                                     </div>
                                     <div className="listingImageCard__img">
                                        <div className="c-ahj8kj">
                                           <img alt="Flagship Flagship Hotel Dream Inn" loading="auto" importance="auto" src="https://images.oyoroomscdn.com/uploads/hotel_image/109667/thumb/7451595c32bc9bb1.jpg" placeholdertype="hotel" className="c-2tglnv listingImageCard__img--full " itemprop="image" />
                                        </div>
                                     </div>
                                     <div className="listingImageCard__img">
                                        <div className="c-ahj8kj">
                                           <img alt="Flagship Flagship Hotel Dream Inn" loading="auto" importance="auto" src="https://images.oyoroomscdn.com/uploads/hotel_image/109667/thumb/71d3e57a204df57e.jpg" placeholdertype="hotel" className="c-2tglnv listingImageCard__img--full listingImageCard__sideImages--lastBorderRadius" itemprop="image" />
                                        </div>
                                     </div>
                                  </div>
                               </div>
                            </div>
                            <div className="hotelCardListing__descriptionWrapper">
                               <div className="oyo-row oyo-row--no-spacing listingHotelDescription" name="HotelListCard-109667" itemscope="true" itemtype="http://schema.org/Hotel">
                                  <meta itemprop="telephone" content="+91 9313 9313 93" />
                                  <meta itemprop="checkinTime" content="12:00:00" />
                                  <meta itemprop="checkoutTime" content="11:00:00" />
                                  <meta itemprop="url" content="https://www.oyorooms.com/109667/" />
                                  <meta itemprop="email" content="bookings@oyorooms.com" />
                                  <meta itemprop="image" content="https://images.oyoroomscdn.com/uploads/hotel_image/109667/1b41af39df1789b7.jpg" />
                                  <div className="oyo-cell--12-col listingHotelDescription__content">
                                     <div itemprop="geo" itemscope="true" itemtype="http://schema.org/GeoCoordinates" />
                                     <meta itemprop="latitude" content="25.625903300537587" />
                                     <meta itemprop="longitude" content="85.05276756361127" />
                                  </div>
                                  <div className="listingHotelDescription__contentWrapper">
                                     <div className="listingHotelDescription__contentWrapper--left">
                                        <a className="c-nn640c u-width100" href="/109667/">
                                           <h3 title="Flagship Flagship Hotel Dream Inn" className="listingHotelDescription__hotelName d-textEllipsis" itemprop="name">Flagship Flagship Hotel Dream Inn</h3>
                                        </a>
                                        <div className="d-body-lg listingHotelDescription__hotelAddress" itemprop="address" itemscope="true" itemtype="http://schema.org/PostalAddress">
                                           <span itemprop="streetAddress" title="Opp R.g Supermarket, Patna" className="u-line--clamp-2" >Opp R.g Supermarket, Patna</span>
                                           <meta itemprop="addressRegion" content="Patna" />
                                           <meta itemprop="addressCountry" content="India" />
                                        </div>
                                     </div>
                                     <div className="listingHotelDescription__contentWrapper--right">
                                        <div className="c-1m75bgc">
                                           <div className="c-1sugwtq">All staff vaccinated with 1st dose</div>
                                        </div>
                                     </div>
                                  </div>
                                  <div className="hotelRating">
                                     <div itemprop="aggregateRating" itemscope="true" itemtype="http://schema.org/AggregateRating" className="hotelRating__wrapper" tabindex="-1">
                                        <meta itemprop="ratingValue" content="4.5" />
                                        <span className="is-fontBold hotelRating__rating hotelRating__rating--excellent hotelRating__rating--clickable">
                                           <span>4.5</span>
                                           <span className="hotelRating__star">
                                              <span lazyload="true" className="c-1in37i9">
                                                 <svg viewBox="0 0 9 10" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.25 9.5a.238.238 0 01-.12-.032L4.5 7.948l-2.63 1.52a.238.238 0 01-.265-.017.271.271 0 01-.102-.26l.48-3.042-1.91-2.021a.276.276 0 01-.061-.268.255.255 0 01.197-.18l2.874-.508L4.276.646A.25.25 0 014.5.5c.094 0 .181.057.223.146l1.194 2.526 2.874.508a.255.255 0 01.197.18.275.275 0 01-.061.268l-1.91 2.021.48 3.042c.015.1-.024.201-.102.26a.242.242 0 01-.145.049z"></path>
                                                 </svg>
                                              </span>
                                           </span>
                                        </span>
                                        <meta itemprop="reviewCount" content="559" />
                                        <meta itemprop="bestRating" content="5" />
                                        <span className="hotelRating__ratingSummary  hotelRating__rating--clickable">(559 Ratings)</span><span className="hotelRating__dot">·</span><span className="hotelRating__ratingSummary">Excellent</span>
                                     </div>
                                  </div>
                                  <div className="amenityWrapper">
                                     <div className="amenityWrapper__amenity">
                                        <span lazyload="true" className="c-1in37i9 amenityWrapper__icon">

                                           <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">

                                              <title>checkmark</title>
                                              <desc>Created with Sketch.</desc>
                                              <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                 <g id="new-icon-set-(exportable)" transform="translate(-121.000000, -698.000000)" fill="black">
                                                    <path d="M132,720 C129.061263,720 126.299684,718.856 124.221263,716.778737 C122.142842,714.701474 121,711.938737 121,709.001158 C121,706.062421 122.144,703.300842 124.221263,701.222421 C126.298526,699.144 129.061263,698 132,698 C134.938737,698 137.700316,699.144 139.778737,701.222421 C141.857158,703.300842 143,706.062421 143,709.001158 C143,711.939895 141.856,714.701474 139.778737,716.778737 C137.701474,718.856 134.938737,720 132,720 L132,720 Z M132,699.157895 C137.427053,699.157895 141.842105,703.572947 141.842105,709 C141.842105,714.427053 137.427053,718.842105 132,718.842105 C126.572947,718.842105 122.157895,714.427053 122.157895,709 C122.157895,703.572947 126.572947,699.157895 132,699.157895 Z M129.684211,713.631579 L129.681895,713.631579 C129.830105,713.631579 129.978316,713.576 130.091789,713.462526 L138.197053,705.357263 C138.422842,705.131474 138.422842,704.764421 138.197053,704.538632 C137.971263,704.312842 137.604211,704.312842 137.378421,704.538632 L129.683053,712.234 L126.619263,709.170211 C126.393474,708.944421 126.026421,708.944421 125.800632,709.170211 C125.574842,709.396 125.574842,709.763053 125.800632,709.988842 L129.274316,713.462526 C129.387789,713.574842 129.536,713.631579 129.684211,713.631579 Z" id="checkmark"></path>
                                                 </g>
                                              </g>
                                           </svg>
                                        </span>
                                        <span className="d-body-sm d-textEllipsis" title="Private entrance"> Private entrance </span>
                                     </div>
                                     <div className="amenityWrapper__amenity">
                                        <span lazyload="true" className="c-1in37i9 amenityWrapper__icon">

                                           <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">

                                              <title>checkmark</title>
                                              <desc>Created with Sketch.</desc>
                                              <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                 <g id="new-icon-set-(exportable)" transform="translate(-121.000000, -698.000000)" fill="black">
                                                    <path d="M132,720 C129.061263,720 126.299684,718.856 124.221263,716.778737 C122.142842,714.701474 121,711.938737 121,709.001158 C121,706.062421 122.144,703.300842 124.221263,701.222421 C126.298526,699.144 129.061263,698 132,698 C134.938737,698 137.700316,699.144 139.778737,701.222421 C141.857158,703.300842 143,706.062421 143,709.001158 C143,711.939895 141.856,714.701474 139.778737,716.778737 C137.701474,718.856 134.938737,720 132,720 L132,720 Z M132,699.157895 C137.427053,699.157895 141.842105,703.572947 141.842105,709 C141.842105,714.427053 137.427053,718.842105 132,718.842105 C126.572947,718.842105 122.157895,714.427053 122.157895,709 C122.157895,703.572947 126.572947,699.157895 132,699.157895 Z M129.684211,713.631579 L129.681895,713.631579 C129.830105,713.631579 129.978316,713.576 130.091789,713.462526 L138.197053,705.357263 C138.422842,705.131474 138.422842,704.764421 138.197053,704.538632 C137.971263,704.312842 137.604211,704.312842 137.378421,704.538632 L129.683053,712.234 L126.619263,709.170211 C126.393474,708.944421 126.026421,708.944421 125.800632,709.170211 C125.574842,709.396 125.574842,709.763053 125.800632,709.988842 L129.274316,713.462526 C129.387789,713.574842 129.536,713.631579 129.684211,713.631579 Z" id="checkmark"></path>
                                                 </g>
                                              </g>
                                           </svg>
                                        </span>
                                        <span className="d-body-sm d-textEllipsis" title="Doctor on call"> Doctor on call </span>
                                     </div>
                                     <div className="amenityWrapper__amenity">
                                        <span lazyload="true" className="c-1in37i9 amenityWrapper__icon">
                                           <svg xmlns="http://www.w3.org/2000/svg" id="parking-facility" viewBox="0 0 22 20">
                                              <path d="M18.265 11.322c-.14-.833-.6-3.383-1.096-4.348-.332-.642-1.18-1.134-2.524-1.46-1.09-.264-2.486-.41-3.927-.41-1.443 0-2.838.145-3.93.41-1.342.327-2.19.818-2.522 1.46-.496.964-.955 3.515-1.096 4.348-.758.505-1.13 1.234-1.13 2.204v2.632c0 .686.453 1.27 1.084 1.488v1.143c0 .58.487 1.052 1.085 1.052h1.085c.598 0 1.085-.473 1.085-1.053v-1.053h8.677v1.052c0 .58.487 1.052 1.085 1.052h1.084c.598 0 1.085-.473 1.085-1.053v-1.144a1.585 1.585 0 0 0 1.086-1.488v-2.632c0-.97-.373-1.7-1.13-2.204zm-7.542-5.39c3.247 0 5.352.706 5.686 1.373.322.645.68 2.352.903 3.572a5.8 5.8 0 0 0-.34-.09c-.3-.07-1.075-.148-1.42-.195l-2.86-2.165c-.252-.18-.547-.117-.728.135-.182.25-.135.45.117.63l1.763 1.26c-.957-.03-2.006-.03-3.123-.03-.132 0-.595.013-.726.013L7.03 8.427c-.26-.172-.51-.124-.68.135-.173.258-.137.47.122.643l1.85 1.23c-1.51.027-2.822.108-3.85.352a8.33 8.33 0 0 0-.34.09c.223-1.22.58-2.927.905-3.572.334-.666 2.44-1.374 5.686-1.374zM5.536 19.098H3.974v-1.363h1.562V19.1zm10.373 0v-1.363h1.56V19.1h-1.56zm2.64-2.755c0 .316-.25.572-.558.572H3.452a.566.566 0 0 1-.558-.572V13.48c0-1.058.543-1.623 1.876-1.948 1.404-.342 3.512-.342 5.953-.342 2.44 0 4.547 0 5.952.342 1.333.325 1.876.89 1.876 1.948v2.864zM5.84 15.295c.707 0 1.282-.557 1.282-1.243s-.575-1.244-1.282-1.244c-.707 0-1.282.558-1.282 1.244 0 .686.575 1.244 1.282 1.244zm0-1.658c.236 0 .427.186.427.415a.42.42 0 0 1-.427.415.42.42 0 0 1-.427-.415c0-.23.19-.415.428-.415zm9.76 1.66c.708 0 1.283-.56 1.283-1.245s-.574-1.244-1.28-1.244c-.707 0-1.282.558-1.282 1.244 0 .686.575 1.244 1.28 1.244zm0-1.66c.237 0 .43.186.43.415a.42.42 0 0 1-.43.415.42.42 0 0 1-.426-.415c0-.23.19-.415.427-.415zM21.51 2.882c.364.093.57.416.46.72l-.024.064c-.11.303-.5.475-.864.382L11.015 1.275.918 4.048c-.364.093-.753-.08-.864-.382L.03 3.6c-.11-.303.096-.626.46-.72L11.014 0 21.51 2.882z"></path>
                                           </svg>
                                        </span>
                                        <span className="d-body-sm d-textEllipsis" title="Parking facility"> Parking facility </span>
                                     </div>
                                     <div className="amenityWrapper__amenity"><span className="d-body-sm"> <span>+ 12 more</span></span></div>
                                  </div>
                               </div>
                               <div class="oyo-cell--12-col listingHotelDescription__priceBtn">
                                  <div class="listingHotelDescription__HotelCategory"></div>
                                    <div class="oyo-row oyo-row--no-spacing">
                                       <div class="oyo-cell--5-col">
                                          <div class="listingPrice">
                                             <meta itemprop="priceRange" content="₹525 - ₹2399" />
                                             <div class="listingPrice__numbers">
                                                <span class="listingPrice__finalPrice">₹525</span>
                                                <span class="listingPrice__slashedPrice d-body-lg">₹2399</span>
                                                <span class="listingPrice__percentage">78% off</span>
                                             </div>
                                             <div class="listingPrice__perRoomNight">per room per night</div>
                                          </div>
                                       </div>
                                       <div class="oyo-cell--7-col listingHotelDescription__btnWrapper"><button class="c-1k6asfw d-whiteButton" type="button"><span>View Details</span></button><button class="c-1k6asfw d-greenButton" type="button"><span>Book Now</span></button></div>
                                    </div>
                               </div>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </section>
         
    </div>
    </div>
    )
 }