import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const arrOfImages = [
"img/branches/dcg/gallery/dcg02.jpg",
"img/branches/kfr/gallery/kfr02.jpeg",
"img/branches/wbcr/gallery/wbcr02.jpeg",
"img/branches/ebcr/gallery/ebcr03.jpg",
"img/branches/dcg/gallery/dcg04.jpg",
"img/branches/kfr/gallery/kfr02.jpeg",
"img/branches/dcg/gallery/dcg04.jpg",
"img/branches/ebcr/gallery/ebcr02.JPG",
"img/branches/wbcr/gallery/wbcr02.jpeg"
];
const Gallary2 = () => {
const [indexOfImages, setIndexOfImages] = useState(0);
const [showModal, setShowModal] = useState(false);
const openModalAndSetIndex = (index) => {
	setIndexOfImages(index);
	setShowModal(true);
	return;
};
return (
	<div id='portfolio2' className='text-center'>
    <div className='container'>
    <div className='section-title'>
          <h2>Gallery</h2>
          <p>
		  पढ़ो और पढने दो
          </p>
        </div>

	{arrOfImages.map((image, index) => (
		<img
		key={image}
		style={{ height: "250px", width: "350px", margin: "5px" }}
		src={image}
		alt={image}
		onClick={() => openModalAndSetIndex(index)}
		/>
	))}

	{/*
        <div>
		<button type="button" onClick={() => setShowModal(true)}>
		Show Lightbox
		</button>
	</div>
    */}

	{showModal && (
		<Lightbox
		mainSrc={arrOfImages[indexOfImages]}
		nextSrc={arrOfImages[(indexOfImages + 1) % arrOfImages.length]}
		prevSrc={
			arrOfImages[
			(indexOfImages + arrOfImages.length - 1) % arrOfImages.length
			]
		}
		onCloseRequest={() => setShowModal(false)}
		onMovePrevRequest={() =>
			setIndexOfImages(
			(indexOfImages + arrOfImages.length - 1) % arrOfImages.length
			)
		}
		onMoveNextRequest={() =>
			setIndexOfImages(
			(indexOfImages + arrOfImages.length + 1) % arrOfImages.length
			)
		}
		/>
	)}
    </div>
	</div>
);
}

export default Gallary2;
