import { Carousel } from 'react-carousel-minimal';

function Crous2() {
    const data = [
       {
         image: "img/branches/dcg/gallery/dcg05.jpg",
         caption: ""
       },
       {
         image: "img/branches/kfr/gallery/kfr02.jpeg",
         caption: ""
       },
       {
         image: "img/branches/wbcr/gallery/wbcr02.jpeg",
         caption: ""
       },
       {
         image: "img/branches/ebcr/gallery/ebcr03.jpg",
         caption: ""
       },
       {
         image: "img/branches/dcg/gallery/dcg04.jpg",
         caption: ""
       },
       {
         image: "img/branches/kfr/gallery/kfr02.jpeg",
         caption: ""
       },
       {
         image: "img/branches/dcg/gallery/dcg04.jpg",
         caption: ""
       },
       {
         image: "img/branches/ebcr/gallery/ebcr02.JPG",
         caption: ""
       },
       {
         image: "img/branches/wbcr/gallery/wbcr02.jpeg",
         caption: ""
       }
     ];
   
     const captionStyle = {
       fontSize: '2em',
       fontWeight: 'bold',
     }
     const slideNumberStyle = {
       fontSize: '20px',
       fontWeight: 'bold',
     }
     return (
      <>
       <div className="App">
         <div style={{ textAlign: "center" }}>
         <div style={{
             padding: "0 0px"
           }}>
             <Carousel
               data={data}
               time={2000}
               width="100%"
               height="500px"
               captionStyle={captionStyle}
               radius="0px"
               slideNumber={true}
               slideNumberStyle={slideNumberStyle}
               captionPosition="bottom"
               automatic={true}
               dots={true}
               pauseIconColor="white"
               pauseIconSize="40px"
               slideBackgroundColor="darkgrey"
               slideImageFit="cover"
               thumbnails={true}
               thumbnailWidth="100px"
               style={{
                 textAlign: "center",
                 maxWidth: "100%",
                 maxHeight: "500px",
                 margin: "40px auto",
               }}
             />
           </div>
         </div>
       </div>
       <div style={{marginBottom:'20vh'}}></div>
       </>
     );
   }
   
   export default Crous2;